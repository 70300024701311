// @ts-nocheck
import axios from 'axios';
import intl from './intl';

const instance = axios.create({
  headers: {'Accept-Language': intl.locale},
});

/** @type {Set<import('axios').CancelTokenSource>} */
const sources = new Set();

instance.interceptors.request.use((req) => {
  const source = axios.CancelToken.source();

  req.cancelToken = source.token;
  req._source = source;

  sources.add(source);

  return req;
});

instance.interceptors.response.use((res) => {
  sources.delete(res.config._source);

  return res;
}, (error) => {
  if (error.response && error.response.config) {
    sources.delete(error.response.config._source);
  }

  return Promise.reject(error);
});

document.addEventListener('axios:cancel', async () => {
  sources.forEach((source) => source.cancel('Aborted due to the clients switch'));
  sources.clear();
});

instance.isCancel = axios.isCancel;

export default instance;
