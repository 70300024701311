import React, {useMemo, useState} from 'react';
import clsx from 'clsx';
import {numeral, moment} from '../../lib';
import {Balance, Alert, Popup, HelperBtn, Loading} from '../../ui';

import './style.scss';

const TABS = {
  FULL_STORY: 'full-story',
  ACCRUALS: 'accruals',
  WRITE_OFFS: 'write-offs'
};

export const MyBalance = ({
  wallet, balanceLoading, accruals, writeOffs, onClose
}) => {
  const [activeTab, setActiveTab] = useState(TABS.FULL_STORY);

  const accrued = numeral(accruals.reduce((sum, item) => sum + (item.amount || 0), 0)).format('0,0.[00] $');
  const writenOff = writeOffs.length > 0 &&
    numeral(writeOffs.reduce((sum, item) => sum - (item.amount || 0), 0)).format('0,0.[00] $');

  const generalHistory = useMemo(() => {
    return [...accruals, ...writeOffs]
      .sort((a, b) => (moment(b.createdAt) > moment(a.createdAt)) ?
        1 :
        ((moment(a.createdAt) > moment(b.createdAt)) ? -1 : 0));
  }, [accruals, writeOffs]);

  return (
    <Popup onClose={onClose}>
      <>
        <Popup.Header
          title="Мой баланс кешШШбека"
          onClose={onClose}
        >
          <div className="mybalance__top">
            <div className="mybalance__top-container">

              <div className="left">
                <Balance
                  type="large"
                  total={wallet?.balance || 0}
                  payout={0}
                  accrued={accrued}
                  writenOff={writenOff}
                />
              </div>

              <div className="right">
                <Alert
                  onClick={() => {}}
                  className="right__item md hover-white"
                  background="#fff"
                >
                  <div className="title">
                    1500
                  </div>
                  <div className="subtitle">
                    кешШШбека начислим в сентябре
                  </div>
                </Alert>
                <Alert
                  onClick={() => {}}
                  className="right__item sm hover-primary"
                  background="#45483C"
                >
                  <div className="title title--sec">
                  Списать бонусы
                  </div>
                </Alert>
                <Alert
                  onClick={() => {}}
                  className="right__item lg hover-white"
                  background="#fff"
                >
                  <div className="title">
                    15
                  </div>
                  <div className="subtitle">
                  чеков сейчас в очереди на проверке
                  </div>
                </Alert>
              </div>
            </div>
          </div>

          <div className="mybalance__top-text">
          1 балл = 1 руб. Списания доступны от 500Б.
          </div>
        </Popup.Header>

        <Popup.Body>
          <div className="mybalance">
            <div className="mybalance__alerts-container">
              <Alert
                onClick={() => setActiveTab(TABS.FULL_STORY)}
                className={clsx('item-alert item-alert--sm', {
                  ['item-alert--active']: activeTab === TABS.FULL_STORY,
                  ['hover-light-olive']: activeTab !== TABS.FULL_STORY
                })}
                background="#F2F3EA">
                <h3 className="title">
                  Вся история
                </h3>
              </Alert>
              <div className="mybalance__alerts-social-container">
                <Alert
                  onClick={() => setActiveTab(TABS.ACCRUALS)}
                  className={clsx('item-alert', {
                    ['item-alert--active']: activeTab === TABS.ACCRUALS,
                    ['hover-light-olive']: activeTab !== TABS.ACCRUALS
                  })}
                  background="#F2F3EA"
                >
                  <h3 className="title">
                    Начисления
                  </h3>
                </Alert>
                <Alert
                  onClick={() => setActiveTab(TABS.WRITE_OFFS)}
                  className={clsx('item-alert', {
                    ['item-alert--active']: activeTab === TABS.WRITE_OFFS,
                    ['hover-light-olive']: activeTab !== TABS.WRITE_OFFS
                  })}
                  background="#F2F3EA"
                >
                  <h3 className="title">
                    Списания
                  </h3>
                </Alert>
              </div>
            </div>

            {balanceLoading && <Loading/>}

            {activeTab === TABS.FULL_STORY && !balanceLoading &&
              <div className="mybalance__list">
                {generalHistory.length > 0 ?
                  <>
                    {generalHistory.map((item) => (
                      <InfoAlert key={item.id} item={item}/>
                    ))}
                  </> :
                  <Alert className="no-content" background="#F5F5F5">
                    <span>Выплат нет</span>
                  </Alert>
                }
              </div>}

            {activeTab === TABS.ACCRUALS && !balanceLoading &&
              <div className="mybalance__list">
                {accruals.length > 0?
                  <>
                    {accruals.map((item) => (
                      <InfoAlert key={item.id} item={item}/>
                    ))}
                  </> :
                  <Alert className="no-content" background="#F5F5F5">
                    <span>Выплат нет</span>
                  </Alert>
                }
              </div>}

            {activeTab === TABS.WRITE_OFFS && !balanceLoading &&
            <div className="mybalance__list">
              {writeOffs.length > 0?
                <>
                  {writeOffs.map((item) => (
                    <InfoAlert key={item.id} item={item}/>
                  ))}
                </> :
                <Alert className="no-content" background="#F5F5F5">
                  <span>Выплат нет</span>
                </Alert>
              }
            </div>}
          </div>
        </Popup.Body>
      </>
    </Popup>
  );
};

const InfoAlert = ({item}) => {
  return (
    <HelperBtn
      type="primary"
      numberAlert={item.amount === 0 ? '' : numeral(item.amount).format('+0,0.[00] $')}
      numberNegative={item.amount < 0}
    >
      {item.amount <= 0 ?
        <>
          <div className="mybalance__item">
            <span className="mybalance__item-title">
              {`Списание: ${(item.comment || '–').toUpperCase()}`}
            </span>
            <span className="mybalance__item-subtitle">
              {moment(item.createdAt).format('DD MMMM YYYY, HH:mm')}
              {' • '}
              списано
            </span>
          </div>
        </> :
        <>
          <div className="mybalance__item">
            <span className="mybalance__item-title">
              {`КешШШбек: ${(item.comment || '–').toUpperCase()}`}
            </span>
            <span className="mybalance__item-subtitle">
              {moment(item.createdAt).format('DD MMMM YYYY, HH:mm')}
              {' • '}
              начислено
            </span>
          </div>
        </>
      }

    </HelperBtn>
  );
};
