import React, {useState} from 'react';
import {PharmacyCard} from '../PharmacyCard';
import {PharmacyInfoModal} from '../PharmacyInfoModal';
import {SkuItemCard} from '../SkuItemCard';
import {Alert, Button, Popup} from '../../ui';

import './style.scss';

const MOCK_PHARMACY = {
  title: 'Аптека АПРЕЛЬ №722',
  address: 'ул. Уличная, д.45/3',
  distance: 3.5,
  skusCount: 5
};

export const SkuInfoModal = ({
  onClose, sku
}) => {
  const [pharmacyInfoModal, setPharmacyInfoModal] = useState({isOpen: false, pharmacy: null});

  return (
    <>
      <Popup
        onClose={onClose}
      >
        <Popup.Header
          title="Информация по препарату"
          onClose={onClose}
        />

        <Popup.Body>
          <div className="skuInfo">

            <Alert
              background="#F5F5F5"
              className="skuInfo__helper-alert"
            >
              <span>
            Покупайте препарат в аптеках, участвующих в акции
 и получайте кешШШбек на ваш бонусный счет
              </span>
            </Alert>

            <div className="skuInfo__sku-card">
              <SkuItemCard
                sku={sku}
              />
            </div>

            <div className="skuInfo__title">
            Препараты с кешШШбеком в аптеке
            </div>

            <div className="skuInfo__pharmacy-list">
              {[...new Array(8)].map((sku, i) => (
                <PharmacyCard
                  pharmacy={MOCK_PHARMACY}
                  onPharmacyInfo={(pharmacy) => setPharmacyInfoModal({
                    isOpen: true,
                    pharmacy
                  })}
                  noLabel={true}
                  key={i}
                />
              ))}
            </div>

          </div>
        </Popup.Body>
      </Popup>

      <Button className="scan-btn scan-btn--fixed">
        <div
          onClick={() => document.dispatchEvent(new Event('modal:scan-check'))}
          className="scan-btn__wrapper"
        >
          <span className="scan-btn__title">Сканировать чек из аптеки</span>
          <span className="scan-btn__subtitle">и получить кешШШбек</span>
        </div>
      </Button>

      {pharmacyInfoModal.isOpen &&
        <PharmacyInfoModal
          pharmacy={pharmacyInfoModal.pharmacy}
          onClose={() => setPharmacyInfoModal({isOpen: false, pharmacy: null})}
        />}
    </>
  );
};
