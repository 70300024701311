import React from 'react';
import {FormattedMessage} from 'react-intl';
import {intl} from '../../../../lib';
import {HelperBtn} from '../../../../ui';
import useAnimationScreen from '../../hooks/useAnimationScreen';

export const VerificationResult = ({checks, isRescannedReceipt, onCheckListOpen}) => {
  useAnimationScreen();

  return <div className="animated-screen-wrapper">
    {!isRescannedReceipt && <>
      <p className="main-title">
        <FormattedMessage id="scanCheck.verificationResult.success.title" values={{
          span: (chunks) => <span>{chunks}</span>,
        }} />
      </p>
      <p className="main-title">
        <FormattedMessage id="scanCheck.verificationResult.success.text" values={{
          span: (chunks) => <span>{chunks}</span>,
        }} />
      </p>
    </>}

    {isRescannedReceipt && <p className="main-title">
      <FormattedMessage id="scanCheck.verificationResult.rescannedReceipt.text" values={{
        span: (chunks) => <span>{chunks}</span>,
      }} />
    </p>}

    {checks.length > 0 && <HelperBtn onClick={onCheckListOpen} type="primary" numberAlert={checks.length}>
      {intl.formatMessage({id: 'scanCheck.helperBtn.verificationChecks'})}
    </HelperBtn>}
  </div>;
};
