import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import config from '../../config';

export const PrivateRoute = (props) => {
  if (!props.hasOwnProperty('user')) {
    throw Error('user prop is required');
  }

  const {user} = props;

  return user ? <Outlet /> : <Navigate to={config.paths.login} />;
};
