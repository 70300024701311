import React, {useState} from 'react';
import {PharmacyCard} from '../PharmacyCard';
import {PharmacyInfoModal} from '../PharmacyInfoModal';
import {PharmaciesMap} from '../PharmaciesMap';
import {Alert, Popup, Select} from '../../ui';

import './style.scss';

const MOCK_CATEGORIES = [
  {
    name: 'ближайшие ко мне',
    id: '0'
  },
  {
    name: 'больше товаров',
    id: '1'
  }
];

const MOCK_PHARMACY = {
  title: 'Аптека АПРЕЛЬ №722',
  address: 'ул. Уличная, д.45/3',
  distance: 3.5,
  skusCount: 5
};

export const PharmaciesModal = ({
  onClose, userLocation
}) => {
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [pharmacyInfoModal, setPharmacyInfoModal] = useState({isOpen: false, pharmacy: null});

  console.log(search, selectedCategory);

  return (
    <>
      <Popup onClose={onClose}>
        <>
          <Popup.Header
            title="Ближайшие аптеки"
            headerIndex={10}
            onClose={onClose}
          >
            <div className="pharmacies__top">
              <PharmaciesMap
                userLocation={userLocation}
              />
            </div>

          </Popup.Header>

          <Popup.Body>
            <div className="pharmacies">
              <h3 className="pharmacies__title">
                Аптеки с кешШШбеком
списком
              </h3>

              <Alert background="#F5F5F5" className="pharmacies__skus-helper-alert">
                <span>
            Сканируйте чеки с акционными препаратами
из аптек в списке и получайте кешШШбек
                </span>
              </Alert>

              <input
                className="search-input"
                style={{background: 'url("../images/icons/search.svg") left 16px top 12px / 24px 24px no-repeat'}}
                placeholder="Поиск по аптекам..."
                onChange={(e) => setSearch(e.target.value)}
              />

              {MOCK_CATEGORIES.length > 0 &&
                  <Select
                    onChange={(value) => setSelectedCategory(value)}
                    options={MOCK_CATEGORIES}
                    label="Сортировать"
                    background="#F2F2F2"
                  />}

              <div className="pharmacies__pharmacy-list">
                {[...new Array(10)].map((sku, i) => (
                  <PharmacyCard
                    pharmacy={MOCK_PHARMACY}
                    onPharmacyInfo={(pharmacy) => setPharmacyInfoModal({
                      isOpen: true,
                      pharmacy
                    })}
                    key={i}
                  />
                ))}
              </div>

            </div>
          </Popup.Body>
        </>
      </Popup>

      {pharmacyInfoModal.isOpen &&
        <PharmacyInfoModal
          pharmacy={pharmacyInfoModal.pharmacy}
          onClose={() => setPharmacyInfoModal({isOpen: false, pharmacy: null})}
          showScanBtn={true}
        />}
    </>
  );
};
