import axios from './axios';
import config from '../config';

/**
 * @param {Blob} file
 * @param {{temp: boolean?, filename: string?}} [opts]
 * @return {Promise<string>} URL
 */
export const upload = async (file, opts = {
  filename: null,
  temp: false,
}) => {
  const data = new FormData();

  if (opts.filename || opts.temp) {
    let filename = opts.temp ? 'temp-' : '';

    filename += (opts.filename || URL.createObjectURL(file));

    data.append('file', file, filename);
    data.append('filename', filename);
  } else {
    data.append('file', file);
  }

  data.append('contentType', file.type);

  const res = await axios.post(config.storageHost + '/v2/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': config.storageToken,
    },
    responseType: 'json',
  });

  if (res.data.status !== 'ok') {
    console.error(res.headers);
    console.error(res.data);
  }

  const url = (res.data.data || {}).fileUrl || '';

  return url ? config.storageHost + url : '';
};
