import React from 'react';
import {useEffect} from 'react';
import {Routes, useLocation} from 'react-router-dom';

export const SwitchPageViews = (props) => {
  const location = useLocation();
  const gtag = window.gtag;
  const ym = window.ym;

  useEffect(() => {
    if (typeof gtag === 'function') {
      gtag('set', 'page_path', location.pathname);
      gtag('event', 'page_view');
    }

    if (typeof ym === 'function') {
      ym(86080614, 'hit', location.pathname, {referer: document.referrer});
    }
  }, [location]);

  return <Routes>{props.children}</Routes>;
};
