import React, {useEffect,
  useLayoutEffect,
  useMemo, useRef} from 'react';
import gsap from 'gsap';
import {toast} from 'react-toastify';
import {intl, QRScanner} from '../../../../lib';
import {Alert} from '../../../../ui';

import './style.scss';

export const ScanQRModal = (props) => {
  const {show, onClose} = props;
  /** @type {React.MutableRefObject<HTMLVideoElement | null>} */
  const streamEl = useRef(null);
  const tmRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      tmRef.current = gsap.timeline()
        .fromTo('.scanQR-modal', {opacity: 0, y: 60}, {opacity: 1, y: 0, duration: 0.4});
    });

    return () => ctx.revert();
  }, []);

  const scanner = useMemo(() => {
    return new QRScanner();
  }, [show]);

  async function scanQR() {
    try {
      const url = await scanner.scanQR(streamEl.current);

      scanner.scannerStop();
      onClose(null, url);
    } catch (e) {
      console.error(e);

      toast(intl.formatMessage({id: 'error.title'}), {
        type: toast.TYPE.ERROR,
      });
    }
  }

  function abort() {
    scanner.scannerStop();
    onClose();
  }

  useEffect(() => {
    if (show) {
      scanQR();
    } else {
      scanner.destroy();
    }
  }, [show]);

  useEffect(() => {
    return () => {
      scanner.destroy();
    };
  }, []);

  return <>
    <div className="dark-bg" onClick={onClose}/>

    <div className="scanQR-modal">
      <div className="scanQR-modal__header" >
        <div className="scanQR-modal__title">
          {intl.formatMessage({id: 'scanCheck.scanQRModal.helper'})}
        </div>
        <span onClick={onClose} className="scanQR-modal__header-close">
          <img src="/images/redesign/close.svg" alt="arrow"/>
        </span>
      </div>

      <div onClick={abort} className="scanQR-modal__close back-block hover-white">
        <img src="/images/icons/scan-check/back-arrow.svg" alt="back"/>
      </div>

      <Alert className="scan-check__helper-alert scanQR-modal__alert">
        {intl.formatMessage({id: 'scanCheck.alerts.scanQrHelper'})}
      </Alert>

      <div className="scanQR-modal__video">
        <video className="scanQR-modal__scanner" ref={streamEl}></video>
        <div className="scanQR-modal__blackout"/>
      </div>
    </div>
  </>;
};
