import {useLayoutEffect, useRef} from 'react';
import {gsap} from 'gsap';

const useAnimationScreen = (horizontal = false) => {
  const tmRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      tmRef.current = horizontal ?
        gsap.timeline().fromTo('.animated-screen-wrapper', {opacity: 0, x: 60}, {opacity: 1, x: 0, duration: 0.6}) :
        gsap.timeline().fromTo('.animated-screen-wrapper', {opacity: 0, y: 60}, {opacity: 1, y: 0, duration: 0.6});
    });

    return () => ctx.revert();
  }, []);
};
export default useAnimationScreen;
