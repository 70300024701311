const ym = window.ym;
const ymId = 87586071;

exports.sendHit = (url, title, referer = '') => {
  if (typeof ym === 'function') {
    ym(ymId, 'hit', url, {
      title: title,
      referer: referer
    });
  }
};

exports.sendParams = (user) => {
  if (typeof ym === 'function') {
    ym(ymId, 'userParams', {
      UserID: user.id,
      UserLogin: user.login,
      UserAdmin: user.permission?.isAdmin,
      AdminLogin: user?.adminLogin,
    });
  }
};

/**
 * Send goal to Yandex.Metrika
 * @link https://yandex.ru/support/metrica/objects/reachgoal.html#reachgoal__param_params
 * @param {Object} params
 * @param {string} goalTitle
 */
exports.sendGoal = (params, goalTitle = 'AWARD_GOAL') => {
  if (typeof ym === 'function') {
    ym(ymId, 'reachGoal', goalTitle, params);
  }
};
