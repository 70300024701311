import React, {useEffect, useLayoutEffect, useRef} from 'react';
import gsap from 'gsap';

import './style.scss';

export const Popup = ({onClose, noBodyAnimation, children, containerRef}) => {
  const timelineRef = useRef();

  let startX = 0;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const diffX = endX - startX;

    if (diffX > 150) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => document.body.classList.remove('no-scroll');
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      timelineRef.current = noBodyAnimation ?
        gsap.timeline()
          .fromTo('.popup',
            {x: 200, y: 0},
            {x: 0, y: 0, duration: 0.4}
          ) :
        gsap.timeline()
          .fromTo('.popup',
            {x: 200, y: 0},
            {x: 0, y: 0, duration: 0.4}
          )
          .fromTo('.popup__wrapper--animated', {opacity: 0, y: 60}, {opacity: 1, y: 0, duration: 0.275});
    });

    return () => ctx.revert();
  }, []);

  return (
    <>
      <div className="popup-dark-bg" onClick={onClose} />
      <div
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        ref={containerRef}
        className="popup custom-scroll-Y"
      >
        <div className="popup__container">
          {children}
        </div>
      </div>
    </>
  );
};

const PopupHeader = ({title, titleColor, headerIndex, onClose, children}) => {
  return (
    <div className="container">
      <div style={headerIndex && {zIndex: headerIndex}} className="popup__header">
        <span onClick={onClose} className="icon">
          <img src="/images/icons/left-arrow.svg" alt="arrow"/>
        </span>
        <h3 style={titleColor && {color: titleColor}} className="title">
          {title}
        </h3>
      </div>

      {children}
    </div>
  );
};

const PopupBody = ({children}) => {
  return (
    <div className="popup__wrapper popup__wrapper--animated">
      <div className="container content">
        {children}
      </div>
    </div>
  );
};

Popup.Header = PopupHeader;
Popup.Body = PopupBody;
