import React from 'react';
import {Logo} from '../../ui';

import './style.scss';

export const Header = ({user}) => {
  let initials = '?';

  if (user?.name || user?.surname) {
    initials = `${user.name[0] || ''}${user.surname[0] || ''}`;
  }

  return <>
    <header className="header">
      <div className="header__container">

        <div className="left">
          <Logo />
        </div>

        <div className="right">
          <div className="account">
            <div
              className="avatar"
              role="button">
              {initials}
            </div>
            <img src="/images/icons/avatar-points.svg" alt="avatar"/>
            <span className="notification-badge">1</span>
          </div>
        </div>
      </div>
    </header>
  </>;
};
