import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {intl} from '../../../../lib';
import useAnimationScreen from '../../hooks/useAnimationScreen';

import './style.scss';

export const ScanCheckFAQ = ({onClose}) => {
  const ref = useRef(null);
  const [hideFog, setHideFog] = useState(false);

  useAnimationScreen(true);

  const handleScroll = () => {
    if (ref.current.scrollHeight - ref.current.scrollTop < ref.current.clientHeight + 54) {
      setHideFog(true);
    } else {
      setHideFog(false);
    }
  };

  useEffect(() => {
    ref.current?.addEventListener('scroll', handleScroll);
    return () => ref.current?.removeEventListener('scroll', handleScroll);
  }, []);

  return <div className={clsx('scan-check-faq animated-screen-wrapper', {
    ['scan-check-faq--hideFog']: hideFog
  })}>
    <div className="back-block hover-white" onClick={onClose}>
      <img src="/images/icons/scan-check/back-arrow.svg" alt="back"/>
    </div>

    <div ref={ref} className="scan-check-faq__content scan-custom-scroll">
      <div className="scan-check-faq__block">
        <div className="helper-title">
          {intl.formatMessage({id: 'scanCheck.faq.title1'})}
        </div>
        <div className="helper-text">
          {intl.formatMessage({id: 'scanCheck.faq.text1'})}
        </div>
      </div>
      <div className="scan-check-faq__block">
        <div className="helper-title">
          {intl.formatMessage({id: 'scanCheck.faq.title2'})}
        </div>
        <div className="helper-text">
          {intl.formatMessage({id: 'scanCheck.faq.text2'})}
        </div>
      </div>
      <div className="scan-check-faq__block">
        <div className="helper-title">
          {intl.formatMessage({id: 'scanCheck.faq.title3'})}
        </div>
        <div className="helper-text">
          {intl.formatMessage({id: 'scanCheck.faq.text3'})}
        </div>
      </div>
    </div>
  </div>;
};
