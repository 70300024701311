import React from 'react';
import {mockData} from '../../mock';

import './style.scss';

export const SkuItemCard = ({sku, onSkuInfoModal}) => {
  const randomImageIndex = Math.floor(Math.random() * mockData.skusImages.length);
  return (
    <div
      onClick={() => {
        if (!onSkuInfoModal) return;

        onSkuInfoModal(sku);
      }}
      className="sku-card"
    >
      <div className="sku-card__container">
        <div className="sku-card__image-wrapper">
          <div className="sku-card__image">
            <img src={mockData.skusImages[randomImageIndex]} alt="sku"/>
          </div>
        </div>

        <div className="sku-card__body">
          <h3 className="sku-card__title">{sku.title}</h3>
          <div className="sku-card__manuf">{sku.manufacturer}</div>

          <div className="sku-card__bonus">
            <span className="sku-card__bonus-text">Бонус за чек:</span>
            <div className="sku-card__bonus-label">
              <span>{sku.pharmacistBonusPerUnit}Б</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
