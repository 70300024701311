import {createIntl, createIntlCache} from 'react-intl';
import messages from '../messages';
import LocaleManager from './locale-manager';

const cache = createIntlCache();
const locale = LocaleManager.get();

const intl = createIntl({
  locale: locale,
  messages: messages[locale]
}, cache);

export default intl;
