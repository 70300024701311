import config from './config';
import translations from './messages/translations.json';
import defaultRuStrings from './messages/messages.json';

function getDefaultStrings(strings) {
  return strings.reduce((result, item) => {
    const {id, defaultMessage} = item;
    result[id] = defaultMessage;

    return result;
  }, {});
}

function populateMissingStrings(strings) {
  for (const key in strings) {
    if (strings.hasOwnProperty(key)) {
      if (strings[key] === '') {
        strings[key] = translations[config.locales.langs[0]][key];
      }
    }
  }

  return strings;
}

export default {
  ru: getDefaultStrings(defaultRuStrings),
  en: populateMissingStrings(translations.en),
  kz: populateMissingStrings(translations.kz)
};
