export const mockData = {
  'user': {
    'VAPIDKey': 'BJwcLm7RQsF59XvhR7QA4sGTyxZXccOQAezNtWEmkqchCB0CCmYuUdX8Ii-ONR7Tk_u2xjuvmSQkR9G2J34aqzA',
    'adminLogin': '',
    'anketaBonus': 0,
    'anketaID': 0,
    'authToken': '465bb793-29ec-4e51-9a91-93c1d20c1e6c',
    'bot': {
      'digestPeriod': 0
    },
    'cashier': '49',
    'cashierDirectMatch': '1',
    'createdAt': '2022-05-25 17:42:17+03',
    'diplomaNumber': 'ВСГ 2513834',
    'diplomaPhotoURL': 'https://storage.pharmhub.ru/uploads/23/10/24/b/9f1ab5ce-911a-4f2a-b4ea-4ded0c7788ce-0954-Агатис,_Сорока.jpg',
    'email': 'agatis07@mail.ru',
    'endpoint': {
      'id': '00000000-0000-0000-0000-000000000000',
      'legalTitle': '',
      'physicalAddress': '',
      'physicaslPostalCode': ''
    },
    'freshchatRestoreId': '41620ac3-d5da-44d6-ad58-b0b7f9025371',
    'geolocation': {
      'Accuracy': 733,
      'Latitude': 55.7434,
      'Longitude': 38.0096,
      'Timestamp': 1703844123962
    },
    'id': 1465,
    'isExternal': false,
    'login': '+79257303318',
    'middlename': 'Николаевна',
    'name': 'Анастасия',
    'permission': {
      'isAccrualLearningBonus': true,
      'isAccrualPracticeBonus': true,
      'isAccrualReferralPercentBonus': true,
      'isAccrualReferralPercentForBonus': false,
      'isAccrualReferralWelcomeBonus': true,
      'isAdmin': false,
      'isBaseAuthenticated': true,
      'isFullAuthenticated': true,
      'isOnlyPendingReferralWelcomeAccruals': false,
      'isPayoutPossible': true
    },
    'pharmacies': [],
    'pharmacyAddress': '140143, Московская обл, г Раменское, дп Родники, ул Б.Учительская, д 4',
    'pharmacyFias': '5564abc7-fed5-4e63-87c8-ba791f27c68b',
    'pharmacyId': 'd5616385-6373-4d44-9a70-9340f0d11b3b',
    'pharmacyInn': '5040075900',
    'pharmacyTitle': 'Агатис',
    'receipt': {
      'authAt': '2023-02-21T13:17:28Z',
      'bonusAt': '0001-01-01T00:00:00Z',
      'createdAt': '2023-02-21T13:17:28Z',
      'fd': '',
      'fn': '',
      'fpd': '3783079763',
      'id': 1146,
      'kkt': '',
      'ofd': '',
      'result': {},
      'sum': '',
      't': '',
      'touchedAt': '2023-02-21T13:17:28Z',
      'userId': 1465,
      'verified': true
    },
    'referralCode': '782cc026',
    'referrerId': 0,
    'referrerWelcomeBonus': 70,
    'roles': [
      'manager'
    ],
    'status': 'verified',
    'surname': 'Сорока',
    'userWebinars': [
      {
        'bonusReceived': false,
        'status': 'planned',
        'userId': 1465,
        'webinarId': 41
      },
      {
        'bonusReceived': false,
        'status': 'planned',
        'userId': 1465,
        'webinarId': 40
      },
      {
        'bonusReceived': false,
        'status': 'planned',
        'userId': 1465,
        'webinarId': 6
      }
    ],
    'verified': {
      'bot': false,
      'email': false,
      'phone': true
    },
    'wallet': {
      'balance': 11023.919921875,
      'id': 1317,
      'paid': 3000,
      'pending': 3502.5400390625
    }
  },
  'wallet': {
    'balance': 11023.92,
    'id': 1317,
    'paid': 3000,
    'payoutAt': '0001-01-01T00:00:00Z',
    'pending': 3502.54,
    'updatedAt': '2023-12-28T12:36:58.726665Z',
    'userId': 1465
  },
  accruals: [
    {
      id: '52923d08-b008-4baa-b89e-c32bb7e222f3',
      walletId: 3276,
      status: 1,
      typeId: 200,
      objectId: 3852,
      objectUUID: '',
      objectId2: 100,
      objectUUID3: '827dd0e3-d037-2891-8c14-b25141c23b87',
      createdAt: '2023-12-12T02:38:35.348884Z',
      updatedAt: '2023-12-12T02:38:35.568974Z',
      comment: 'АНВИМАКС ТАБ ШИП №10 МАЛИНА',
      amount: 2.2,
      referral: {
        login: '+79620826814',
        fullName: 'Жолдина Галина Александровна'
      }
    },
    {
      id: '52923d08-b008-4baa-b89e-a32bb7e222f3',
      walletId: 3276,
      status: 1,
      typeId: 200,
      objectId: 3852,
      objectUUID: '',
      objectId2: 100,
      objectUUID3: '827dd0e3-d037-2891-8c14-b25141c23b87',
      createdAt: '2023-12-12T02:38:35.348884Z',
      updatedAt: '2023-12-12T02:38:35.568974Z',
      comment: 'АНВИМАКС ТАБ ШИП №10 МАЛИНА',
      amount: 100,
      referral: {
        login: '+79620826814',
        fullName: 'Жолдина Галина Александровна'
      }
    },
    {
      id: '52923d08-b008-4baa-b89e-k32bb7e222f3',
      walletId: 3276,
      status: 1,
      typeId: 200,
      objectId: 3852,
      objectUUID: '',
      objectId2: 100,
      objectUUID3: '827dd0e3-d037-2891-8c14-b25141c23b87',
      createdAt: '2023-12-12T02:38:35.348884Z',
      updatedAt: '2023-12-12T02:38:35.568974Z',
      comment: 'АНВИМАКС ТАБ ШИП №10 МАЛИНА',
      amount: 99,
      referral: {
        login: '+79620826814',
        fullName: 'Жолдина Галина Александровна'
      }
    },
  ],
  writeOffs: [
    {
      id: '52923d08-b008-4baa-b89e-b32bb7e222f3',
      walletId: 3276,
      status: 1,
      typeId: 200,
      objectId: 3852,
      objectUUID: '',
      objectId2: 100,
      objectUUID3: '827dd0e3-d037-2891-8c14-b25141c23b87',
      createdAt: '2023-12-12T02:38:35.348884Z',
      updatedAt: '2023-12-12T02:38:35.568974Z',
      comment: 'АНВИМАКС ТАБ ШИП №10 МАЛИНА',
      amount: -500,
      referral: {
        login: '+79620826814',
        fullName: 'Жолдина Галина Александровна'
      }
    },
    {
      id: '52923d08-b008-4baa-b89e-e32bb7e222f3',
      walletId: 3276,
      status: 1,
      typeId: 200,
      objectId: 3852,
      objectUUID: '',
      objectId2: 58,
      objectUUID3: '827dd0e3-d037-2891-8c14-b25141c23b87',
      createdAt: '2023-12-12T02:38:35.348884Z',
      updatedAt: '2023-12-12T02:38:35.568974Z',
      comment: 'АНВИМАКС ТАБ ШИП №10 МАЛИНА',
      amount: -75,
      referral: {
        login: '+79620826814',
        fullName: 'Жолдина Галина Александровна'
      }
    },
    {
      id: '52923d08-b008-4baa-b89e-i32bb7e222f3',
      walletId: 3276,
      status: 1,
      typeId: 200,
      objectId: 3852,
      objectUUID: '',
      objectId2: 100,
      objectUUID3: '827dd0e3-d037-2891-8c14-b25141c23b87',
      createdAt: '2023-12-12T02:38:35.348884Z',
      updatedAt: '2023-12-12T02:38:35.568974Z',
      comment: 'АНВИМАКС ТАБ ШИП №10 МАЛИНА',
      amount: -5000,
      referral: {
        login: '+79620826814',
        fullName: 'Жолдина Галина Александровна'
      }
    },
  ],
  skus: [
    {
      'addressId': 0,
      'skuId': 27645,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 23,
      'pharmacistBonusMultiple': 1,
      'title': 'ЭКОФОМУРАЛ ГРАН 3 Г',
      'brandTitle': 'ЭКОФОМУРАЛ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Фосфомицин'
      ],
      'cgCount': 156,
      'bonus': '25,65 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000055971710526315786,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null,
    },
    {
      'addressId': 0,
      'skuId': 28713,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 24,
      'pharmacistBonusMultiple': 1,
      'title': 'ХЕЛИ-СТОП ТАБ 120МГ №56',
      'brandTitle': 'ХЕЛИ-СТОП',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Противоязвенные'
      ],
      'cgCount': 156,
      'bonus': '26,12 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0008370426606627266,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 24324,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': 'АВВА Рус',
      'pharmacistBonusPerUnit': 13,
      'pharmacistBonusMultiple': 1,
      'title': 'СИМЕОТИК КАПС 40 МГ №25',
      'brandTitle': 'СИМЕОТИК',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Метеоризм'
      ],
      'cgCount': 156,
      'bonus': '13,84 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0001168784295062463,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27411,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': 'АВВА Рус',
      'pharmacistBonusPerUnit': 18,
      'pharmacistBonusMultiple': 1,
      'title': 'СИМЕОТИК КАПЛИ 100 МГ/МЛ 30 МЛ',
      'brandTitle': 'СИМЕОТИК',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Метеоризм'
      ],
      'cgCount': 156,
      'bonus': '19,58 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0008381450653983353,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27642,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 47,
      'pharmacistBonusMultiple': 1,
      'title': 'НИКОЗАМ ТАБ 1,5 МГ №100',
      'brandTitle': 'НИКОЗАМ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '51,64 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000020736298005040796,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 24320,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 15,
      'pharmacistBonusMultiple': 1,
      'title': 'МОТОНИУМ ТАБ 10 МГ №30',
      'brandTitle': 'МОТОНИУМ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Пищеварение'
      ],
      'cgCount': 156,
      'bonus': '16,11 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00023002793296089384,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27639,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 12,
      'pharmacistBonusMultiple': 1,
      'title': 'МАЛЬВАЦИД СУСП 170 МЛ',
      'brandTitle': 'МАЛЬВАЦИД',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Антациды'
      ],
      'cgCount': 156,
      'bonus': '12,83 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000018338725761772857,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 24316,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 12,
      'pharmacistBonusMultiple': 1,
      'title': 'МАЛЬВАЦИД ТАБ ЖЕВ 400 МГ + 400 МГ №24',
      'brandTitle': 'МАЛЬВАЦИД',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Антациды'
      ],
      'cgCount': 156,
      'bonus': '13,3 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00002837589218995594,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 24311,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': 'АВВА Рус',
      'pharmacistBonusPerUnit': 15,
      'pharmacistBonusMultiple': 1,
      'title': 'МАГНИКАРДИЛ ТАБ 150 МГ + 30,39 МГ №100',
      'brandTitle': 'МАГНИКАРДИЛ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Антиагреганты'
      ],
      'cgCount': 156,
      'bonus': '16,2 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0008437499999999999,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27636,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 13,
      'pharmacistBonusMultiple': 1,
      'title': 'ЛАРИНГОТАБ ТАБ №30',
      'brandTitle': 'ЛАРИНГОТАБ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Боль в горле'
      ],
      'cgCount': 156,
      'bonus': '14,18 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00007691283446712018,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27631,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 12,
      'pharmacistBonusMultiple': 1,
      'title': 'ДИНОЛАК ЭМУЛ 200 МЛ',
      'brandTitle': 'ДИНОЛАК',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Слабительные'
      ],
      'cgCount': 156,
      'bonus': '13,19 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00036874046290579973,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 24302,
      'manufacturer': 'АВВА Рус',
      'notBrandManufacturer': 'АВВА Рус',
      'pharmacistBonusPerUnit': 99,
      'pharmacistBonusMultiple': 1,
      'title': 'ДЕТРУС ТАБ 100 МГ + 900 МГ №60',
      'brandTitle': 'ДЕТРУС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Варикоз'
      ],
      'cgCount': 156,
      'bonus': '109,46 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0011217369701361215,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 1497,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 20,
      'pharmacistBonusMultiple': 1,
      'title': 'УРОНОРМИН-Ф ПОР 3 Г №1',
      'brandTitle': 'УРОНОРМИН-Ф',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Фосфомицин'
      ],
      'cgCount': 156,
      'bonus': '27,68 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00007541606186793575,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 1498,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 48,
      'pharmacistBonusMultiple': 1,
      'title': 'УРОНОРМИН-Ф ПОР 3 Г №2',
      'brandTitle': 'УРОНОРМИН-Ф',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Фосфомицин'
      ],
      'cgCount': 156,
      'bonus': '52,65 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00014206153846153848,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 25642,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 23,
      'pharmacistBonusMultiple': 1,
      'title': 'ТРАВАЛЗА ДУО КАПЛИ 5 МГ/МЛ + 40 МКГ/МЛ 2,5 МЛ',
      'brandTitle': 'ТРАВАЛЗА',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Офтальмологические'
      ],
      'cgCount': 156,
      'bonus': '24,39 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00005900645756457565,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 1465,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 9,
      'pharmacistBonusMultiple': 1,
      'title': 'РИНОСТОП АКВА ФОРТЕ СПРЕЙ 125 МЛ',
      'brandTitle': 'РИНОСТОП',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Насморк'
      ],
      'cgCount': 156,
      'bonus': '9,36 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00003636140625,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 1462,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 9,
      'pharmacistBonusMultiple': 1,
      'title': 'РИНОСТОП АКВА НОРМ СПРЕЙ 125 МЛ',
      'brandTitle': 'РИНОСТОП',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Насморк',
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '9,36 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00009271335937499999,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 18
      }
    },
    {
      'addressId': 0,
      'skuId': 1461,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 20,
      'pharmacistBonusMultiple': 1,
      'title': 'РИНОСТОП АКВА БЕБИ СПРЕЙ 125 МЛ',
      'brandTitle': 'РИНОСТОП',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Насморк',
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '9,36 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0002630671875,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 40
      }
    },
    {
      'addressId': 0,
      'skuId': 25641,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 20,
      'pharmacistBonusMultiple': 1,
      'title': 'ОКУЛАРИС АНТИСЕПТ КАПЛИ 500 МКГ/МЛ 10 МЛ',
      'brandTitle': 'ОКУЛАРИС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Офтальмологические'
      ],
      'cgCount': 156,
      'bonus': '21,67 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0005340451111111111,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 25640,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 17,
      'pharmacistBonusMultiple': 1,
      'title': 'ОКОФЕНАК КАПЛИ 0,09% 5 МЛ',
      'brandTitle': 'ОКОФЕНАК',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Офтальмологические'
      ],
      'cgCount': 156,
      'bonus': '17,96 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0001578371743896081,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 1419,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 20,
      'pharmacistBonusMultiple': 1,
      'title': 'МАКСИКОЛД ОТОТИТА КАПЛИ 1% + 4% 15 МЛ',
      'brandTitle': 'МАКСИКОЛД',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Отит',
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '6,82 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0012224654445642587,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 40
      }
    },
    {
      'addressId': 0,
      'skuId': 1410,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 20,
      'pharmacistBonusMultiple': 1,
      'title': 'МАГНЕЛИС-В6 ТАБ №50',
      'brandTitle': 'МАГНЕЛИС-В6',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '–',
      'hasCrossSellingSKUTag': false,
      'score': 0.0052756,
      'minQuantity': 2,
      'pharmacyStatus': 'shared',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 203,
          'bonus': 100,
          'isCompleted': false,
          'name': 'Обучение Магнелис-B6 таб №5',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-12-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 40
      }
    },
    {
      'addressId': 0,
      'skuId': 25639,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 23,
      'pharmacistBonusMultiple': 1,
      'title': 'ДОМИЗИА ДУО КАПЛИ 20 МГ/МЛ + 5 МГ/МЛ 5 МЛ',
      'brandTitle': 'ДОМИЗИА',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Офтальмологические'
      ],
      'cgCount': 156,
      'bonus': '25,11 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000196241935483871,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 25638,
      'manufacturer': 'АО Отисифарм',
      'notBrandManufacturer': 'АО Отисифарм',
      'pharmacistBonusPerUnit': 23,
      'pharmacistBonusMultiple': 1,
      'title': 'БРИМАЙЗА ДУО КАПЛИ 2 МГ/МЛ + 5 МГ/МЛ 5 МЛ',
      'brandTitle': 'БРИМАЙЗА',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Офтальмологические'
      ],
      'cgCount': 156,
      'bonus': '24,39 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00016766236162361623,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 28660,
      'manufacturer': 'Авексима',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 11,
      'pharmacistBonusMultiple': 1,
      'title': 'ТРАНАВЕКС ТАБ 250 МГ №30',
      'brandTitle': 'ТРАНАВЕКС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Гемостатическое'
      ],
      'cgCount': 156,
      'bonus': '12,66 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00019805866666666667,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 22404,
      'manufacturer': 'Авексима',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 17,
      'pharmacistBonusMultiple': 1,
      'title': 'НОБАЗИТ ТАБ 250 МГ №20',
      'brandTitle': 'НОБАЗИТ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'ОРВИ'
      ],
      'cgCount': 156,
      'bonus': '18,45 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0008081012195121952,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 22405,
      'manufacturer': 'Авексима',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 22,
      'pharmacistBonusMultiple': 1,
      'title': 'НОБАЗИТ ФОРТЕ ТАБ 500 МГ №20',
      'brandTitle': 'НОБАЗИТ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'ОРВИ'
      ],
      'cgCount': 156,
      'bonus': '24,3 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0011920333333333335,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 11104,
      'manufacturer': 'Доминанта-Сервис $',
      'notBrandManufacturer': 'Доминанта-Сервис',
      'pharmacistBonusPerUnit': 5,
      'pharmacistBonusMultiple': 1,
      'title': 'СУПРАВИТ МАГНИЙ + В-КОМПЛЕКС ТАБ ШИП №20',
      'brandTitle': 'СУПРАВИТ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Магний'
      ],
      'cgCount': 156,
      'bonus': '4,95 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.01769318181818182,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 11098,
      'manufacturer': 'Доминанта-Сервис $',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 13,
      'pharmacistBonusMultiple': 1,
      'title': 'СУПРАВИТ ВИТАМИН-С ТАБ ШИП 850 МГ №20',
      'brandTitle': 'СУПРАВИТ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Витамин С'
      ],
      'cgCount': 156,
      'bonus': '13,5 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 1.8453825,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27058,
      'manufacturer': 'Доминанта-Сервис $',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 34,
      'pharmacistBonusMultiple': 1,
      'title': 'ГАСТРОБЕНЕ БЕБИ ПОР №10',
      'brandTitle': 'ГАСТРОБЕНЕ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '1%',
      'hasCrossSellingSKUTag': false,
      'score': 0.04150344827586207,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 192,
          'bonus': 100,
          'isCompleted': false,
          'name': 'Обучение Гастробене Беби ',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 4,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 136
      }
    },
    {
      'addressId': 0,
      'skuId': 27052,
      'manufacturer': 'Доминанта-Сервис $',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 19,
      'pharmacistBonusMultiple': 1,
      'title': 'ГАСТРОБЕНЕ КИДС ЖЕВ ТАБ 1 Г №20',
      'brandTitle': 'ГАСТРОБЕНЕ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Микрофлора'
      ],
      'cgCount': 156,
      'bonus': '20,93 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.3707724453694068,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27054,
      'manufacturer': 'Доминанта-Сервис $',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 27,
      'pharmacistBonusMultiple': 1,
      'title': 'ГАСТРОБЕНЕ ПЛЮС ТАБ №18',
      'brandTitle': 'ГАСТРОБЕНЕ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Микрофлора',
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '23,63 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.5333242775510204,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 200,
          'bonus': 100,
          'isCompleted': false,
          'name': 'Обучение Гастробене плюс таб. №18',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 4,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 108
      }
    },
    {
      'addressId': 0,
      'skuId': 23070,
      'manufacturer': 'ЗАО "Канонфарма продакшн"',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 18,
      'pharmacistBonusMultiple': 1,
      'title': 'ПАЛЬМАПРОСТ КАПС 320 МГ №60',
      'brandTitle': 'ПАЛЬМАПРОСТ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Простатит'
      ],
      'cgCount': 156,
      'bonus': '19,4 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00009383057171339764,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27602,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 40,
      'pharmacistBonusMultiple': 1,
      'title': 'ФИТОСЕПТ ЛЕДЕНЦЫ №20 МЕД ЛИМОН',
      'brandTitle': 'ФИТОСЕПТ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '–',
      'hasCrossSellingSKUTag': false,
      'score': 0.00024360000000000004,
      'minQuantity': 2,
      'pharmacyStatus': 'shared',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 206,
          'bonus': 50,
          'isCompleted': false,
          'name': 'ФИТОСЕПТ ЛЕДЕНЦЫ №20 МЕД ЛИМОН',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-12-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 80
      }
    },
    {
      'addressId': 0,
      'skuId': 27601,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 11,
      'pharmacistBonusMultiple': 1,
      'title': 'ТРИГАН-ФЛЕКС КРЕМ 25 Г',
      'brandTitle': 'ТРИГАН-ФЛЕКС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '12,14 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00025471113614027746,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27599,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 44,
      'pharmacistBonusMultiple': 1,
      'title': 'ТОБРАЗОН КАПЛИ 0,1% 0,3% 5 МЛ',
      'brandTitle': 'ТОБРАЗОН',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '48,51 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0004224489795918367,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27596,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 27,
      'pharmacistBonusMultiple': 1,
      'title': 'РАБЕЛОК ТАБ 20 МГ №14',
      'brandTitle': 'РАБЕЛОК',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '29,7 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0001156090909090909,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27597,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 45,
      'pharmacistBonusMultiple': 1,
      'title': 'РАБЕЛОК ТАБ 20 МГ №28',
      'brandTitle': 'РАБЕЛОК',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '49,5 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0004263545454545454,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 27593,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 44,
      'pharmacistBonusMultiple': 1,
      'title': 'КУПИД-36 ТАБ 20 МГ №2',
      'brandTitle': 'КУПИД-36',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Эректильная дисфункция'
      ],
      'cgCount': 156,
      'bonus': '48,51 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0014693877551020405,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 29025,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 17,
      'pharmacistBonusMultiple': 1,
      'title': 'ЗЕЛИКС ДЛЯ ЖЕНЩИН ТАБ №30',
      'brandTitle': 'ЗЕЛИКС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '18,96 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000009449812915972229,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 29026,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 67,
      'pharmacistBonusMultiple': 1,
      'title': 'ЗЕЛИКС ДЛЯ МУЖЧИН ТАБ №30',
      'brandTitle': 'ЗЕЛИКС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '18,96 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00003943416739329587,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 193,
          'bonus': 50,
          'isCompleted': false,
          'name': 'ЗЕЛИКС ДЛЯ МУЖЧИН ТАБ №30',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 134
      }
    },
    {
      'addressId': 0,
      'skuId': 29027,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 17,
      'pharmacistBonusMultiple': 1,
      'title': 'ЗЕЛИКС КАРДИО ТАБ №30',
      'brandTitle': 'ЗЕЛИКС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '18,96 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0011117426959967329,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 29028,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 67,
      'pharmacistBonusMultiple': 1,
      'title': 'ЗЕЛИКС ОСТЕО ТАБ №30',
      'brandTitle': 'ЗЕЛИКС',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '18,96 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.004381574154810653,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 197,
          'bonus': 50,
          'isCompleted': false,
          'name': 'ЗЕЛИКС ОСТЕО ТАБ №30',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 134
      }
    },
    {
      'addressId': 0,
      'skuId': 29029,
      'manufacturer': 'Кадила',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 105,
      'pharmacistBonusMultiple': 1,
      'title': 'ГЕПАСЕЙВ КАПС №60',
      'brandTitle': 'ГЕПАСЕЙВ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '1%',
      'hasCrossSellingSKUTag': false,
      'score': 0.000017032085561497326,
      'minQuantity': 1,
      'pharmacyStatus': 'joining',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 191,
          'bonus': 50,
          'isCompleted': false,
          'name': 'ГЕПАСЕЙВ КАПС №60',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-10-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 210
      }
    },
    {
      'addressId': 0,
      'skuId': 22459,
      'manufacturer': 'КсантисФарма',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 23,
      'pharmacistBonusMultiple': 1,
      'title': 'ПИН АП ТАБ 20 МГ №4',
      'brandTitle': 'ПИН',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Эректильная дисфункция'
      ],
      'cgCount': 156,
      'bonus': '25,31 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000056056962962962964,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 24038,
      'manufacturer': 'КсантисФарма',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 8,
      'pharmacistBonusMultiple': 1,
      'title': 'МОТОГАСТРИК ТАБ 10 МГ №30',
      'brandTitle': 'МОТОГАСТРИК',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Пищеварение'
      ],
      'cgCount': 156,
      'bonus': '8,39 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000012183351873893077,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 29022,
      'manufacturer': 'Леккер',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 3,
      'pharmacistBonusMultiple': 1,
      'title': 'ЛЕККЕР ПЕРЕКИСЬ ВОДОРОДА СПРЕЙ 3% 30 МЛ',
      'brandTitle': 'ЛЕККЕР',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '2,85 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0000543095191976584,
      'minQuantity': 1,
      'pharmacyStatus': 'inactive',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 17640,
      'manufacturer': 'Леккер',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 2,
      'pharmacistBonusMultiple': 1,
      'title': 'ЛЕККЕР БРИЛЛИАНТОВЫЙ ЗЕЛЕНЫЙ РАСТВОР ФЛОМАСТЕР 5 МЛ',
      'brandTitle': 'ЛЕККЕР',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [],
      'cgCount': 156,
      'bonus': '1,61 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00010030890111981077,
      'minQuantity': 1,
      'pharmacyStatus': 'inactive',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 23005,
      'manufacturer': 'ОЗОН-МНН',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 7,
      'pharmacistBonusMultiple': 1,
      'title': 'ТРОМБОСТЕН ТАБ 100 МГ №100',
      'brandTitle': 'ТРОМБОСТЕН',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Антиагреганты'
      ],
      'cgCount': 156,
      'bonus': '7,43 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.0000060176859504132225,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 26862,
      'manufacturer': 'ОЗОН-МНН',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 11,
      'pharmacistBonusMultiple': 1,
      'title': 'СИНЕФИСТИЛ КАПЛИ 20 МЛ',
      'brandTitle': 'СИНЕФИСТИЛ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Аллергия'
      ],
      'cgCount': 156,
      'bonus': '1%',
      'hasCrossSellingSKUTag': false,
      'score': 5.296296296296296e-7,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 26808,
      'manufacturer': 'ОЗОН-МНН',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 22,
      'pharmacistBonusMultiple': 1,
      'title': 'ГАСТОМИЗАН КАПЛИ 30 МЛ',
      'brandTitle': 'ГАСТОМИЗАН',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Метеоризм'
      ],
      'cgCount': 156,
      'bonus': '1%',
      'hasCrossSellingSKUTag': false,
      'score': 0.00012571428571428572,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 7454,
      'manufacturer': 'ПРОМОМЕД',
      'notBrandManufacturer': 'ПРОМОМЕД',
      'pharmacistBonusPerUnit': 55,
      'pharmacistBonusMultiple': 1,
      'title': 'РЕДУКСИН ФОРТЕ ТАБ 850 МГ + 15 МГ №30',
      'brandTitle': 'РЕДУКСИН',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Похудение'
      ],
      'cgCount': 156,
      'bonus': '60,77 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.000013352293106163391,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 7448,
      'manufacturer': 'ПРОМОМЕД',
      'notBrandManufacturer': 'ПРОМОМЕД',
      'pharmacistBonusPerUnit': 58,
      'pharmacistBonusMultiple': 1,
      'title': 'РЕДУКСИН КАПС 15 МГ + 153,5 МГ №30',
      'brandTitle': 'РЕДУКСИН',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Похудение'
      ],
      'cgCount': 156,
      'bonus': '63,81 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00015003672017428892,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 28672,
      'manufacturer': 'Самсон-Мед',
      'notBrandManufacturer': 'Самсон-Мед',
      'pharmacistBonusPerUnit': 130,
      'pharmacistBonusMultiple': 1,
      'title': 'РЕНОБРЕЙН СМ ЛИОФИЛИЗАТ 10 МГ №10',
      'brandTitle': 'РЕНОБРЕЙН',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '–',
      'hasCrossSellingSKUTag': false,
      'score': 0.0010309,
      'minQuantity': 2,
      'pharmacyStatus': 'shared',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 205,
          'bonus': 50,
          'isCompleted': true,
          'name': 'Обучение Ренобрейн 10 мг №10',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-12-01T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 260
      }
    },
    {
      'addressId': 0,
      'skuId': 28585,
      'manufacturer': 'СмартЛайф',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 192,
      'pharmacistBonusMultiple': 1,
      'title': 'СМАРТЛАЙФ ИОНИЗИРУЮЩИЙ ЦИНК 16 МГ 100 МЛ',
      'brandTitle': 'СМАРТЛАЙФ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Прогресс'
      ],
      'cgCount': 156,
      'bonus': '–',
      'hasCrossSellingSKUTag': false,
      'score': 0.00001728,
      'minQuantity': 2,
      'pharmacyStatus': 'shared',
      'taskLevels': [
        {
          'level': 1,
          'activityId': 201,
          'bonus': 100,
          'isCompleted': true,
          'name': 'ИОНИЗИРУЮЩИЙ ЦИНК 16 МГ 100 МЛ',
          'type': 'learning',
          'relative': true
        }
      ],
      'progressReceipt': {
        'current': 0,
        'target': 2,
        'startDate': '2023-12-11T00:00:00.000Z',
        'endDate': '2023-12-31T23:59:59.999Z',
        'bonus': 384
      }
    },
    {
      'addressId': 0,
      'skuId': 28589,
      'manufacturer': 'СмартЛайф',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 29,
      'pharmacistBonusMultiple': 1,
      'title': 'СМАРТЛАЙФ ЛИПОСОМАЛЬНЫЙ ВИТАМИН-Д СПРЕЙ 10 МЛ',
      'brandTitle': 'СМАРТЛАЙФ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Витамин D'
      ],
      'cgCount': 156,
      'bonus': '32 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00003122007992325912,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
    {
      'addressId': 0,
      'skuId': 28583,
      'manufacturer': 'СмартЛайф',
      'notBrandManufacturer': '',
      'pharmacistBonusPerUnit': 74,
      'pharmacistBonusMultiple': 1,
      'title': 'СМАРТЛАЙФ ЛИПОСОМАЛЬНЫЙ ВИТАМИН-С ФЛАК 500 МГ 100 МЛ',
      'brandTitle': 'СМАРТЛАЙФ',
      'cgId': '0',
      'cg': 'Без категории',
      'tags': [
        'Витамин С'
      ],
      'cgCount': 156,
      'bonus': '82 ₽/шт.',
      'hasCrossSellingSKUTag': false,
      'score': 0.00011403071726485174,
      'minQuantity': 1,
      'pharmacyStatus': 'shared',
      'taskLevels': [],
      'progressReceipt': null
    },
  ],
  skusImages: [
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrp8S20rBGBV0UBaTBgN7NEXjdapN9RRnLCg&usqp=CAU',
    'https://png.pngtree.com/png-clipart/20200701/original/pngtree-medicine-portfolio-flat-illustration-png-image_5406491.jpg',
    'https://rg.ru/uploads/images/230/38/10/iStock-1035004756.jpg',
    'https://aif-s3.aif.ru/images/019/521/7988056fefe8d654685d0be806bc2ec8.jpg',
    'https://s0.bloknot-volgodonsk.ru/thumb/850x0xcut/upload/iblock/2a9/fofp11ar1nanyk9bt0odrkoc7esnkyqw/Vydeleny-dopolnitelnye-sredstva-na-priobretenie-besplatnykh-lekarstv-_2_.jpg',
    'https://fmimg.finmarket.ru/nws8/090620/tass3.jpg',
    'https://services.kontur.ru/Files/Modules/Article/22407_big_md_1x.png?t=1646487518',
    'https://api.zabnews.ru/storage/images/posts/ac/e5dbfd79-cd1a-433e-b84c-5dfff47d26b6.jpg',
    'https://todaykhv.ru/upload/resized/232/232484c122f3f4e474ad96c89d8fa4cc.jpg',
    'https://secretmag.ru/thumb/1200x0/filters:quality(75):no_upscale()/imgs/2023/04/24/12/5883192/f96cb5610d304c44c5f6b9eecadcf940ce8af597.jpg'
  ]
};
