import React, {useEffect, useRef, useState} from 'react';
import {clsx} from 'clsx';

import './style.scss';

export const Select = ({
  onChange, options, value = null, label,
  disabled, error, background
}) => {
  const selectRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const selectedItem = value ? options.find((item) => item.id === value) : null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className="new-select">
      <div
        onClick={() => {
          if (disabled) return;
          setOpen((prev) => !prev);
        }}
        className={clsx('new-select__select', {
          ['new-select__select--error']: error,
          ['new-select__select--disable']: disabled,
          ['hover-white']: !error && !disabled,
        })}
        style={background && {background}}
      >

        {label && <span className={clsx('new-select__label', {
          ['new-select__label--disabled']: disabled
        })}>{label}</span>}

        <span className={clsx({
          ['new-select__text--disabled']: disabled
        })}>{selectedItem ? selectedItem.name : selected.name}</span>
        <img
          className={clsx('new-select__icon', {
            ['new-select__icon--rotate']: open
          })}
          src="/images/icons/select-arrow.svg"
          alt="arrow"
        />
      </div>

      {open && <div className="new-select__option-list custom-scroll-Y">
        {options.map((item) => (
          <div
            onClick={() => {
              onChange(item.id);
              setSelected(item);
              setOpen(false);
            }}
            key={item.id}
            className={clsx('new-select__option', {
              ['new-select__option--selected']: item.id === (selectedItem ? selectedItem.id : selected.id)
            })}
          >
            {item.name}
          </div>
        ))}
      </div>}
    </div>
  );
};
