import React from 'react';
import {HelperBtn} from '../../ui';

import './style.scss';

export const PharmacyCard = ({pharmacy, onPharmacyInfo, noLabel}) => {
  return (
    <HelperBtn
      onClick={() => {
        if (!onPharmacyInfo) return;

        onPharmacyInfo(pharmacy);
      }}
      type="primary"
      numberAlert={noLabel ? null : `${pharmacy.skusCount} товаров`}
      noArrow={true}
    >
      <div className="pharmacies__pharmacy-card pharmacy-card">
        <span className="pharmacy-card__title">
          {pharmacy.title}
        </span>
        <span className="pharmacy-card__subtitle">
          {pharmacy.address}
          {' • '}
          {pharmacy.distance}км
        </span>
      </div>
    </HelperBtn>
  );
};
