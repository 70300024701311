import qs from 'qs';
const config = require('../config');

export const OFD_UNKNOWN = 'unknown';
export const OFD_OOFDKZ = 'kazakhtelecom';
export const OFD_KOFDKZ = 'jusan';
export const RU_DATETIME = 'YYYYMMDDTHHmmss';
export const KZ_DATETIME = 'YYYY-MM-DDTHH:mm';

/**
 * @typedef FiscalData
 * @property {string} ofd
 * @property {string} fd
 * @property {string} fn
 * @property {string} fpd
 * @property {string} kkt
 * @property {string} t
 * @property {string} sum
 */

/**
 * @param {string} val
 * @return {FiscalData}
 */
export const parseQR = (val) => {
  let q = {};

  // KZ QRs contain an entire url
  if (config.isKZ) {
    const url = new URL(val);

    for (const [k, v] of url.searchParams.entries()) {
      q[k] = v;
    }
  } else {
    q = qs.parse(val, {ignoreQueryPrefix: true});
  }

  console.log('QR value:', val, 'parsed:', q);

  if (config.isKZ) {
    return {
      ofd: /kofd.kz/.test(val) ? OFD_KOFDKZ : OFD_OOFDKZ,
      fpd: q.i || '',
      kkt: q.f || '',
      t: q.t || '',
      sum: q.s || '',
    };
  } else {
    return {
      ofd: OFD_UNKNOWN,
      fd: q.i || '',
      fn: q.fn || '',
      fpd: q.fp || '',
      t: q.t || '',
      sum: q.s || '',
    };
  }
};
