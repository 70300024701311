/**
 * @return {Promise<GeolocationPosition|null>}
 * @throws {GeolocationPositionError}
 */
export const getCurrentPosition = async () => {
  return new Promise((resolve, reject) => {
    if (typeof navigator.geolocation === 'undefined') {
      return resolve(null);
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (err) => {
        reject(err);
      },
      {enableHighAccuracy: true}
    );
  });
};
