import QRious from 'qrious';
import QRScanner from './qr-scanner';
import axios from './axios';

const SESSION_POLL_INTERVAL = 2000; // ms
const SESSION_POLL_TIMEOUT = 5 * 60 * 1000; // ms, 5 min

export default class SessionQR {
  constructor() {
    /** @type {number?} */
    this.sessionPollInterval = null;
    /** @type {QRScanner} */
    this.scanner = new QRScanner();
  }

  destroy() {
    if (this.sessionPollInterval) {
      window.clearInterval(this.sessionPollInterval);
    }

    this.scanner.scannerStop();
  }

  /**
   * @param {HTMLCanvasElement?} el
   * @param {number} size
   * @return {Promise<string>}
   */
  async render(el, size = 240) {
    if (!el) {
      return '';
    }

    const session = await this.createNewSession();
    let url = `${location.origin}/api/v1/login/session/${session.id}`;

    if (session.userId > 0 && session.loggedIn) {
      url += '?mode=redirect';
    }

    // @ts-ignore
    new QRious({
      element: el,
      size,
      mime: 'image/svg',
      value: url,
    });

    return session.id;
  }

  /**
   * @private
   * @return {Promise<{id: string, userId: number, loggedIn: boolean}>}
   */
  async createNewSession() {
    const res = await axios.post('/api/v1/login/session');

    return res.data?.data;
  }

  /**
   * Run on a guest device awaiting an authorization of an empty session
   * @param {string} sessionID
   * @return {Promise<Object?>}
   */
  async pollForSession(sessionID) {
    if (!sessionID) {
      return null;
    }

    return new Promise((resolve, reject) => {
      let elapsed = 0;

      this.sessionPollInterval = window.setInterval(async () => {
        try {
          const res = await axios.get('/api/v1/login/session/' + sessionID);

          if (res.data?.data?.userId > 0) {
            if (this.sessionPollInterval) {
              window.clearInterval(this.sessionPollInterval);
            }

            resolve(res.data.data);
          }

          elapsed += SESSION_POLL_INTERVAL;

          if (elapsed > SESSION_POLL_TIMEOUT) {
            if (this.sessionPollInterval) {
              window.clearInterval(this.sessionPollInterval);
            }

            resolve(null);
          }
        } catch (e) {
          reject(e);
        }
      }, SESSION_POLL_INTERVAL);
    });
  }

  /**
   * Run by authorized device with a camera to authorize an empty session
   * that will be polled on a guest device
   * @param {string} sessionURL
   * @return {Promise<boolean>}
   */
  static async authorize(sessionURL) {
    const res = await axios.get(sessionURL);

    return res.data?.data?.userId > 0 && res.data?.data?.loggedIn;
  }
}
