import React from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import clsx from 'clsx';
import {intl} from '../../../../lib';
import config from '../../../../config';
import {OFD_OOFDKZ, OFD_KOFDKZ} from '../../../../lib/fiscal';
import {Alert, Select, Input} from '../../../../ui';
import {MANUAL_REQUISITES} from '../..';
import useAnimationScreen from '../../hooks/useAnimationScreen';

import './style.scss';

let OFDS = [];

if (config.isKZ) {
  OFDS = [
    {id: OFD_OOFDKZ, name: 'АО «Казахтелеком»'},
    {id: OFD_KOFDKZ, name: 'АО «Jusan Mobile»'},
  ];
}

const getTitle = (manualReqType) => {
  if (manualReqType === MANUAL_REQUISITES.ENTER) {
    return 'scanCheck.manualReqEnterScreen.enterTitle';
  }
  if (manualReqType === MANUAL_REQUISITES.CHECK) {
    return 'scanCheck.manualReqEnterScreen.checkTitle';
  }

  return 'scanCheck.manualReqEnterScreen.onPhotoTitle';
};

export const ManualReqEnter = (props) => {
  const {
    onClose, manualReqType, error, validated,
    ofd, setOFD, fd, setFD, fn, setFN,
    fpd, setFPD, kkt, setKKT, date, setDate,
    time, setTime, sum, setSum,
    onOfdOperatorHelper
  }= props;
  useAnimationScreen(true);

  const isRequisitesEnter = manualReqType === MANUAL_REQUISITES.ENTER;
  const isRequisitesPhoto = manualReqType === MANUAL_REQUISITES.ON_PHOTO;

  return <div className="manual-req-enter animated-screen-wrapper">
    <div className="back-block hover-white" onClick={onClose}>
      <img src="/images/icons/scan-check/back-arrow.svg" alt="back"/>
    </div>

    <div className="manual-req-enter__content">
      <div className="main-title">
        <FormattedMessage id={getTitle(manualReqType)} values={{
          span: (chunks) => <span>{chunks}</span>,
        }} />
      </div>

      <form className={clsx('manual-req-enter__form scan-custom-scroll', {
        ['manual-req-enter__form--photo']: isRequisitesPhoto
      })}>
        {OFDS.length > 0 &&
          <Select
            onChange={(value) => setOFD(value)}
            options={OFDS}
            error={!ofd && validated}
          />}

        {config.isKZ && <div className="manual-req-enter__ofdHelper">
          <span onClick={onOfdOperatorHelper}>
            {intl.formatMessage({id: 'scanCheck.manualReqEnterScreen.ofdHelper'})}
          </span>
        </div>}

        <div className="manual-req-enter__input-container">
          {!config.isKZ && <Input
            value={fd}
            onChange={(value) => setFD(value)}
            type="number"
            label= {intl.formatMessage({id: 'scanCheck.fdInput.label'})}
            placeholder={intl.formatMessage({id: 'scanCheck.fdInput.placeholder'})}
            error={!isRequisitesEnter && (!fd || fd <= 0)}
          />}

          {!config.isKZ && <Input
            value={fn}
            onChange={(value) => {
              if (value.length > 16) return;
              setFN(value);
            }}
            type="number"
            label={intl.formatMessage({id: 'scanCheck.fnInput.label'})}
            helperLabel={intl.formatMessage({id: 'scanCheck.fnInput.helperLabel'})}
            placeholder={intl.formatMessage({id: 'scanCheck.fnInput.placeholder'})}
            error={!isRequisitesEnter && (!fn || fn <= 0 || fn.length !== 16)}
          />}

          <Input
            value={fpd}
            onChange={(value) => setFPD(value)}
            type="number"
            label={intl.formatMessage({id: 'scanCheck.fpInput.label'})}
            placeholder={intl.formatMessage({id: 'scanCheck.fpInput.placeholder'})}
            error={!isRequisitesEnter && (!fpd || fpd <= 0)}
          />

          {config.isKZ && <Input
            value={kkt}
            onChange={(value) => setKKT(value)}
            type="number"
            label={intl.formatMessage({id: 'scanCheck.kktInput.label'})}
            placeholder={intl.formatMessage({id: 'scanCheck.kktInput.placeholder'})}
            error={!isRequisitesEnter && (!kkt || kkt <= 0)}
          />}

          <Input
            value={date}
            onChange={(value) => setDate(value)}
            type="date"
            label={intl.formatMessage({id: 'scanCheck.dateInput.label'})}
            placeholder={intl.formatMessage({id: 'scanCheck.dateInput.placeholder'})}
            error={!isRequisitesEnter && (!date || !(moment(date, 'YYYY-MM-DD', true).isValid()))}
          />

          <Input
            value={time}
            onChange={(value) => setTime(value)}
            type="time"
            label={intl.formatMessage({id: 'scanCheck.timeInput.label'})}
            placeholder={intl.formatMessage({id: 'scanCheck.dateInput.placeholder'})}
            error={!isRequisitesEnter && (!time || !(moment(time, ['HH:mm:ss', 'HH:mm'], true).isValid()))}
          />

          <Input
            value={sum}
            onChange={(value) => setSum(value)}
            type="number"
            label={intl.formatMessage({id: 'scanCheck.summInput.label'})}
            placeholder={intl.formatMessage({id: 'scanCheck.summInput.placeholder'})}
            error={!isRequisitesEnter && (!sum || sum <= 0)}
          />

          {!!error && <Alert className="scan-check__helper-alert" background="#EBEFD2">
            {error}
          </Alert>}
        </div>
      </form>
    </div>
  </div>;
};
