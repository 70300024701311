import config, {receiptStatuses} from '../config';
import moment from './moment';

export function phoneFormatted(value) {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    const intlCode = (match[1] ? '+7 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
  }
  return value;
}

export function isEmailValid(email) {
  return String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isManagerRole(user) {
  return user?.roles && user.roles.includes(config.roles.MANAGER_ROLE);
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isCashierRole(user) {
  return user?.roles && user.roles.includes(config.roles.CASHIER_ROLE);
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isAnyCashierRole(user) {
  return user?.roles && (
    user.roles.includes(config.roles.CASHIER_ROLE) ||
    user.roles.includes(config.roles.CASHIER_EXT_ROLE) ||
    user.roles.includes(config.roles.CASHIER_DEMO_ROLE)
  );
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isAnyManagerRole(user) {
  return user?.roles && (
    user.roles.includes(config.roles.MANAGER_ROLE) ||
    user.roles.includes(config.roles.MANAGER_EXT_ROLE) ||
    user.roles.includes(config.roles.MANAGER_DEMO_ROLE)
  );
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isMedicalRepRole(user) {
  return user?.roles && user.roles.includes(config.roles.MEDICALREP_ROLE);
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isMarketerRole(user) {
  return user?.roles && user.roles.includes(config.roles.MARKETER_ROLE);
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isGroupMarketerRole(user) {
  return user?.roles && user.roles.includes(config.roles.GROUP_MARKETER_ROLE);
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isOwnerRole(user) {
  return user?.roles && user.roles.includes(config.roles.OWNER_ROLE);
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isExternalRole(user) {
  return user?.roles && (
    user.roles.includes(config.roles.CASHIER_EXT_ROLE) ||
    user.roles.includes(config.roles.MANAGER_EXT_ROLE)
  );
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isDemoRole(user) {
  if (!user) {
    return false;
  }

  return !user.roles ||
    (
      user.roles.includes(config.roles.MANAGER_DEMO_ROLE) ||
      user.roles.includes(config.roles.CASHIER_DEMO_ROLE)
    ) ||
    (isExternalRole(user) && !user.permission?.isBaseAuthenticated);
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isUnknownRole(user) {
  return user && !user.roles;
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isNoRole(user) {
  return !user?.roles || user.roles.length === 0;
}

/**
 * @param {Object} user
 * @return {Boolean}
 */
export function isPharmacyBound(user) {
  return !!user && !!user.pharmacyId;
}

/**
 * Check if Yandex.Metrika access to pages
 * @return {Boolean}
 */
export function isBot() {
  return window.navigator.userAgent.toLowerCase().includes('yandexmetrika') ||
    document.referrer === 'https://metrika.yandex.ru/';
}

/**
 * Mock user data for Yandex Metrika
 * @return {Object}
 */
export function mockUser() {
  return {
    VAPIDKey: '',
    anketaBonus: 70,
    anketaID: 0,
    authToken: '',
    cashier: '',
    checkDate: '',
    createdAt: '2023-01-19 17:44:35+03',
    email: 'test@assistant.matchflow.ru',
    fpd: '',
    id: 2658,
    isExternal: false,
    login: '+79990000000',
    middlename: 'A',
    name: 'Yandex',
    pharmacyAddress: '430014',
    pharmacyFias: '',
    pharmacyId: '',
    pharmacyInn: '12345678',
    pharmacyTitle: 'Test',
    position: 'MANAGER_ROLE',
    referralCode: '',
    referrerId: 0,
    roles: ['manager'],
    surname: 'Test',
    permission: {
      isAccrualLearningBonus: true,
      isAccrualPracticeBonus: true,
      isAccrualReferralPercentBonus: true,
      isAccrualReferralWelcomeBonus: true,
      isAdmin: false,
      isBaseAuthenticated: true,
      isFullAuthenticated: false,
      isOnlyPendingReferralWelcomeAccruals: false,
      isPayoutPossible: false
    },
    adminLogin: 'bot',
    status: 'pending'
  };
}

/**
 * @param {string} value
 * @return {Boolean}
 */
export function validAddress(value) {
  return /^(?=.*[а-яА-Яa-zA-Z\s])(?=.*\d)/.test(value);
}

/**
 * For testing isKZ on test website
 * example: xxx.test1.xxx.ru/#/login?kz
 * @return {boolean}
 */
export function checkKzQueryParam() {
  const regex = /^[\w-]+\.test\d\.[\w-]+\.ru$/i;
  const isTest = regex.test(document.location.host);

  if (!isTest) {
    return false;
  }

  const hashString = window.location.hash;
  const queryString = hashString.split('?')[1];
  const urlParams = new URLSearchParams(queryString);
  return urlParams.has('kz');
}

export function getReceiptStatus(check) {
  const ZERO_DATE = '01-01-01';
  const DAYS_3 = 3 * 24 * 60 * 60 * 1000;
  const DAYS_10 = 10 * 24 * 60 * 60 * 1000;

  if (check.result?.auth?.errorCode === 5 || check.result?.bonus?.errorCode === 5) {
    return receiptStatuses.ERROR;
  }

  const isAuthZeroDate = moment(check.authAt).format('DD-MM-YY') === ZERO_DATE;
  const isBonusZeroDate = moment(check.bonusAt).format('DD-MM-YY') === ZERO_DATE;

  if (!isBonusZeroDate) {
    return receiptStatuses.SUCCESS;
  }

  if (
    (isAuthZeroDate && moment().diff(moment(check.createdAt)) > DAYS_10) ||
    (isBonusZeroDate && moment().diff(moment(check.createdAt)) > DAYS_3)
  ) {
    return receiptStatuses.ERROR;
  }

  return receiptStatuses.PENDING;
}

/**
 * Mock user wallet for Yandex Metrika
 * @return {Object}
 */
export function mockWallet() {
  return {
    balance: 0,
    id: 2244,
    paid: 0,
    payoutAt: '0001-01-01T00:00:00Z',
    pending: 0,
    updatedAt: '2023-01-25T13:50:33.780506+04:00',
    userId: 2658
  };
}
