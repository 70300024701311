import React from 'react';
import {clsx} from 'clsx';

import './style.scss';

export const Badge = ({count, background, className}) => {
  return (
    <div
      style={{backgroundColor: background ? background : '#fff'}}
      className={clsx('badge', className)}
    >
      <span>{count}</span>
    </div>
  );
};
