import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {intl, axios, upload} from '../../../../lib';
import config from '../../../../config';
import {Loading} from '../../components';
import {Alert, HelperBtn} from '../../../../ui';
import {OFD_KOFDKZ} from '../../../../lib/fiscal';
import useAnimationScreen from '../../hooks/useAnimationScreen';
import {MANUAL_REQUISITES} from '../..';

import './style.scss';

const inputId = 'scan-photo-input';
const OCR_POLLING_INTERVAL = 5000;

export const ScanMethods = ({
  onManualReqEnterOpen,
  onQRModalOpen,
  onNewDeviceQROpen,
  handleQRValue,
  setDate,
  setTime,
  setFPD,
  setKKT,
  setSum,
  setFD,
  setFN,
  setOFD,
  setValidated,
  setManualReqType
}) => {
  const inputRef = useRef(null);
  const [photo, setPhoto] = useState({value: ''});
  const [taskID, setTaskID] = useState(null);
  const [processingInterval, setProcessingInterval] = useState(null);
  const [photoProcessing, setPhotoProcessing] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [error, setError] = useState('');

  useAnimationScreen();

  const imageChange = async (e) => {
    setSizeError(false);

    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size <= config.maxAllowedSize) {
        setPhoto(e.target.files[0]);
        onPhoto(e);
      } else {
        setError('');
        setSizeError(true);
      }
    }

    e.target.value = null;
  };

  const onPhoto = async (e) => {
    if (!e.target.value) {
      setPhotoProcessing(false);
      setPhoto({value: ''});
      return;
    }

    try {
      setPhotoProcessing(true);
      setError('');

      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      photo.value = await upload(e.target.files[0], {temp: true});

      setPhoto({...photo});

      setTaskID((await axios.post('/api/v1/ocr/receipt', {
        storageURL: photo.value,
      })).data?.data || '');
    } catch (e) {
      console.error(e);
      setError(intl.formatMessage({id: 'scanCheck.scanMethodScreen.photoUpload.photoError'}));
      setPhotoProcessing(false);
    }
  };

  const onInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    if (!taskID) {
      if (processingInterval) {
        clearInterval(processingInterval);
        setProcessingInterval(null);
      }
      return;
    }

    const interval = setInterval(async () => {
      try {
        const res = (await axios.get(`/api/v1/ocr/receipt/${taskID}`)).data?.data;

        if (!res || res.status === 'started') {
          return;
        }

        if (res.status === 'failed') {
          setTaskID(null);
          setPhoto({value: ''});
          setPhotoProcessing(false);
          setError(intl.formatMessage({id: 'qrScan.modal.error.ocrError'}));
          return;
        }

        const f = res.results || {};

        if (f.codeType === 'qr') {
          handleQRValue(f.value);
        } else {
          setDate(f.date);
          setTime(f.time);

          if (config.isKZ) {
            setFPD(f.fpd || '');
            setKKT(f.kkt || '');
            setSum(String(f.sum || ''));

            if (/kofd.kz/.test(f.ofdURL)) {
              setOFD(OFD_KOFDKZ);
            }
          } else {
            setFD(f.fd || '');
            setFN(f.fn || '');
            setFPD(f.fpd || '');
            setSum(String(f.sum || ''));
          }

          setValidated(true);
        }

        setTaskID(null);
        setPhoto({value: ''});
        setManualReqType(MANUAL_REQUISITES.ON_PHOTO);
        onManualReqEnterOpen();
        setPhotoProcessing(false);
      } catch (e) {
        console.error(e);
        setError(intl.formatMessage({id: 'scanCheck.scanMethodScreen.photoUpload.photoError'}));
        setTaskID(null);
        setPhoto({value: ''});
        setPhotoProcessing(false);
      }
    }, OCR_POLLING_INTERVAL);

    setProcessingInterval(interval);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [taskID]);

  return <>
    <div className="scanMethods animated-screen-wrapper scan-custom-scroll">
      <div className="main-title">
        <FormattedMessage id="scanCheck.scanMethodScreen.title" values={{
          span: (chunks) => <span>{chunks}</span>,
        }} />
      </div>

      <div className="scanMethods__btn-container">
        <HelperBtn type="secondary" onClick={onQRModalOpen}>
          {intl.formatMessage({id: 'scanCheck.scanMethodScreen.qrBtn'})}
        </HelperBtn>

        <input
          ref={inputRef}
          id={inputId}
          name={inputId}
          type="file"
          accept="image/*"
          className="hidden-input"
          onChange={imageChange}
        />
        <HelperBtn type="secondary" onClick={onInputClick}>
          {intl.formatMessage({id: 'scanCheck.scanMethodScreen.photoBtn'})}
        </HelperBtn>
        {sizeError &&
          <Alert
            className="scan-check__helper-alert scan-check__helper-alert--noMb scan-check__helper-alert--danger"
            background="#FFECDB"
          >
            {intl.formatMessage({id: 'scanCheck.scanMethodScreen.photoUpload.sizeError'})}
          </Alert>}
        {error &&
          <Alert
            className="scan-check__helper-alert scan-check__helper-alert--noMb scan-check__helper-alert--danger"
            background="#FFECDB"
          >
            {error}
          </Alert>}

        <HelperBtn type="bordered" onClick={onManualReqEnterOpen}>
          {intl.formatMessage({id: 'scanCheck.scanMethodScreen.manualBtn'})}
        </HelperBtn>
      </div>

      <div className="scanMethods__no-camera">
        {intl.formatMessage({id: 'scanCheck.scanMethodScreen.noCamera'})}
      </div>

      <HelperBtn onClick={onNewDeviceQROpen} type="primary">
        {intl.formatMessage({id: 'scanCheck.scanMethodScreen.connectPhone'})}
      </HelperBtn>
    </div>

    {photoProcessing && <div className="scanMethods__loading">
      <Loading />
    </div>}
  </>;
};
