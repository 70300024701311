import React from 'react';
import {FormattedMessage} from 'react-intl';
import clsx from 'clsx';
import {receiptStatuses} from '../../../../config';
import {moment, intl} from '../../../../lib';
import {Alert} from '../../../../ui';
import useAnimationScreen from '../../hooks/useAnimationScreen';

import './style.scss';

const ZERO_DATE = '01-01-01';

const getErrorCodeTest = (errorCode) => {
  if (errorCode === 2 || errorCode === 3) return 'scanCheck.checkInfo.error.pharmacyBound.cashierDismatch';
  if (errorCode === 4) return 'scanCheck.checkInfo.error.pharmacyBound.noInfo';

  return 'scanCheck.checkInfo.error.pharmacyBound.noCheck';
};

export const CheckInfo = ({check, onClose}) => {
  const bonusShort = intl.formatMessage({id: 'practice.filter.bonus'});

  useAnimationScreen(true);

  return <div className="scan-check-checkInfo animated-screen-wrapper">
    <div className="back-block hover-white" onClick={onClose}>
      <img src="/images/icons/scan-check/back-arrow.svg" alt="back"/>
    </div>

    <div className="scan-check-checkInfo__body">
      {check.status === receiptStatuses.PENDING && <>
        <p className="main-title main-title--pending">
          <FormattedMessage id="scanCheck.checkInfo.pending.text1" values={{
            span: (chunks) => <span>{chunks}</span>,
          }} />
        </p>
        <p className="main-title">
          {intl.formatMessage({id: 'scanCheck.checkInfo.pending.text2'})}
        </p>
      </>}
      {check.status === receiptStatuses.ERROR && <>
        <p className="main-title main-title--mr main-title--error">
          <FormattedMessage id="scanCheck.checkInfo.error.text1" values={{
            span: (chunks) => <span>{chunks}</span>,
          }} />
        </p>
        <p className="main-title">
          {intl.formatMessage({id: 'scanCheck.checkInfo.error.text2'})}
        </p>
      </>}

      {check.status === receiptStatuses.SUCCESS && <>
        <p className="main-title">
          <FormattedMessage id="scanCheck.checkInfo.success.title" values={{
            span: (chunks) => <span>{chunks}</span>,
          }} />
        </p>

        <div className="scan-check-checkInfo__sku-container scan-check-sku scan-custom-scroll">
          {moment(check.check.authAt).format('DD-MM-YY') !== ZERO_DATE &&
          <Alert
            className="scan-check__helper-alert scan-check__helper-alert--noMb"
            background="#EBEFD2"
          >
            {intl.formatMessage({id: 'scanCheck.checkInfo.success.pharmacyBound'})}
          </Alert>}

          {check.check.result?.auth?.errorCode &&
          <Alert
            className="scan-check__helper-alert scan-check__helper-alert--danger scan-check__helper-alert--noMb"
            background="#FFECDB"
          >
            {intl.formatMessage({id: getErrorCodeTest(check.check.result.auth.errorCode)})}
          </Alert>}

          {check.check.result?.bonus?.body?.products.map((item, index) => (
            <div key={index} className="scan-check-btn-container">
              <div className={clsx('scan-check-sku__sku-info', {
                ['scan-check-sku__sku-info--success']: item.mf?.bonus
              })}>
                <div className="sku-title">
                  {item.name}
                </div>
                {item.mf?.manufacturer && <div className="sku-subtitle">{item.mf.manufacturer}</div>}
              </div>
              <div className={clsx('scan-check-sku__bonus-info', {
                ['scan-check-sku__bonus-info--success']: item.mf?.bonus
              })}>
                <div className="sku-title">{intl.formatMessage({id: 'scanCheck.checkInfo.success.bonus'})}</div>
                {item.mf?.bonus ?
                  <div className="sku-title">+{item.mf.bonus + bonusShort}</div> :
                  <div className="sku-subtitle">{intl.formatMessage({id: 'no'})}</div>
                }
              </div>
            </div>
          ))}
        </div>
      </>}
    </div>
  </div>;
};
