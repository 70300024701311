import React from 'react';

import './style.scss';

export const Loading = () => {
  return (
    <div className="scan-check-loading">
      <span></span><span></span><span></span>
    </div>
  );
};
