import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

import './style.scss';

export const CustomSwiper = ({slides}) => {
  return (
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      <>
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <SliderCard/>
          </SwiperSlide>
        ))}
      </>
    </Swiper>
  );
};

const SliderCard = () => {
  return (
    <div className="slider-card">
      <img className="slider-card__bg" src="/images/mock-slide.png" alt="slide"/>
      <div className="slider-card__body">
        <h2 className="slider-card__text">Получайте 25%
        кешШШбека
        за препараты
        ОЗОН!
        </h2>
        <span className="slider-card__tag">Реклама ›</span>
      </div>
    </div>
  );
};
