import React from 'react';
import clsx from 'clsx';

import './style.scss';

export const Input = ({
  value, onChange, label, helperLabel, placeholder,
  type, error, disabled, ...props
}) => {
  return (
    <div className={clsx('scan-check-helperInput', {
      ['scan-check-helperInput--error']: error
    })}>
      {label && <div className={clsx('scan-check-helperInput__label', {
        ['scan-check-helperInput__label--disabled']: disabled
      })}>{label}</div>}

      <input
        className="scan-check-helperInput__input"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type || 'text'}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />

      {helperLabel && value && <div className="scan-check-helperInput__helperLabel">{helperLabel}</div>}
    </div>
  );
};
