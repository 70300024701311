import React from 'react';
import {clsx} from 'clsx';
import {receiptStatuses} from '../../config';

import './style.scss';

export const HelperBtn = ({
  children, type, disabled, numberAlert,
  background, numberNegative, status, noArrow,
  ...props
}) => {
  const getArrowPrefix = () => {
    if (disabled) return 'disable-';
    if (type === 'secondary') return 'white-';

    return '';
  };

  const getBgStatus = () => {
    if (status === receiptStatuses.SUCCESS) return '#EBEFD2';
    if (status === receiptStatuses.ERROR) return '#FFECDB';

    return '#F5F5F5';
  };

  return (
    <button
      className={clsx(`scan-check-helperBtn scan-check-helperBtn--${type ? type : 'primary'}`, {
        ['hover-white']: (type === 'primary' || type === 'bordered') && !disabled,
        ['hover-primary']: type === 'secondary' && !disabled,
        ['scan-check-helperBtn--disabled']: disabled,
      })}
      style={status && {background: getBgStatus()}}
      disabled={disabled}
      {...props}
    >
      {children}
      {numberAlert &&
        <span className={clsx('scan-check-helperBtn__number-alert', {
          ['scan-check-helperBtn__number-alert--negative']: numberNegative,
          ['scan-check-helperBtn__number-alert--noArrow']: noArrow
        })}>
          {numberAlert}
        </span>}
      {status && <span className={clsx('scan-check-helperBtn__indicator', {
        ['scan-check-helperBtn__indicator--success']: status === receiptStatuses.SUCCESS,
        ['scan-check-helperBtn__indicator--error']: status === receiptStatuses.ERROR,
      })}/>}
      {!noArrow && <img
        className="scan-check-helperBtn__icon"
        src={`/images/icons/helper-alert-${getArrowPrefix()}arrow.svg`}
        alt="arrow"
      />}
    </button>
  );
};
