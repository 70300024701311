import React from 'react';
import {clsx} from 'clsx';

import './style.scss';

export const Button = ({type, hoverClass, className, children, ...props}) => {
  return (
    <button
      className={
        clsx(`custom-btn custom-btn--${type ? type : 'primary'} 
        ${hoverClass ? hoverClass : 'hover-primary'} ${className ? className : ''}`
        )}
      {...props}
    >
      {children}
    </button>
  );
};
