import React from 'react';
import {intl, moment} from '../../../..//lib';
import {getReceiptStatus} from '../../../../lib/helper';
import {HelperBtn} from '../../../../ui';
import useAnimationScreen from '../../hooks/useAnimationScreen';

import './style.scss';

export const CheckList = ({checks, onClose, onCheckInfoOpen}) => {
  useAnimationScreen(true);

  return <div className="scan-check-checkList animated-screen-wrapper">
    <div className="back-block hover-white" onClick={onClose}>
      <img src="/images/icons/scan-check/back-arrow.svg" alt="back"/>
    </div>

    <h3 className="main-title">
      {intl.formatMessage({id: 'scanCheck.checkList.title'})}
    </h3>

    <div className="scan-check-checkList__list scan-custom-scroll">
      {checks.map((item) => (
        <HelperBtn
          key={item.id}
          onClick={() => onCheckInfoOpen(item, getReceiptStatus(item))}
          status={getReceiptStatus(item)}
          type="primary"
        >
          {moment(item.createdAt).format('DD-MM-YY, H:mm:ss')}
        </HelperBtn>
      ))}
    </div>
  </div>;
};
