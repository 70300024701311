/**
 * LocalStorage compatible store for usage in web and electron
 */
class Store {
  /**
   * @param {string} storageKey where filters are stored
   */
  constructor(storageKey) {
    this.key = storageKey;

    this._localStorage = window.localStorage;
  }

  /**
   * @return {?string}
   */
  get() {
    return this._localStorage.getItem(this.key) || undefined;
  }

  /**
   * @param {string} value
   * @return {void}
   */
  set(value) {
    this._localStorage.setItem(this.key, value);
  }

  /**
   * @return {void}
   */
  delete() {
    this._localStorage.removeItem(this.key);
  }
}

export default Store;
