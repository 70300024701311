import React from 'react';
import clsx from 'clsx';
import {numeral} from '../../lib';

import './style.scss';

export const Balance = ({
  className, total, accumulate,
  accrued, writenOff, ...props
}) => {
  const balanceValueFormatted = numeral(total).format('0,0.[00] $');

  return (
    <div
      className={clsx('balance', 'balance--large', className)}
      {...props}
    >
      <div className="item">
        <div className="top">
          <div className="title">
            {balanceValueFormatted}
          </div>
        </div>
        <div className="bottom">
          <div className="bottom-left">
            <div className="bonus">
              <img
                className="icon"
                src="/images/icons/balance-right-arrow.svg" alt="arrow"
              />
              <span>{accrued}</span>
            </div>
            <div className="text">
              начислено всего
            </div>
          </div>
          <div className="bottom-right">
            <div className="bonus">
              <img
                className="icon"
                src="/images/icons/balance-bottom-arrow.svg" alt="arrow"
              />
              <span>{writenOff}</span>
            </div>
            <div className="text">
              списано вами
            </div>
          </div>
        </div>
        <div className="decoration">
          <img
            src="/images/balance-decoration.png"
            width={72} height={116} alt=""
          />
        </div>
      </div>
    </div>
  );
};
