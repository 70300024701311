import numeral from 'numeral';
import numeralConfig from './numeral-config';

numeral.register('locale', 'rub', Object.assign({}, numeralConfig, {
  currency: {
    symbol: 'Б'
  },
}));

numeral.locale('rub');

export default numeral;
