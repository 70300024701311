import React, {useEffect, useMemo, useState} from 'react';
import {numeral} from '../../lib';
import {MyBalance, PharmaciesModal, SkuInfoModal, SkuItemCard} from '../../components';
import {Alert, Badge, Button, Select, Swiper} from '../../ui';
import {mockData} from '../../mock';

import './style.scss';

const MOCK_CATEGORIES = [
  'Простуда',
  'Грипп',
  'Ангина'
];

const ANY_CATEGORY = {
  name: 'Любая категория',
  id: ''
};
const NO_CATEGORY = {
  name: 'Без категории',
  id: 'Без категории'
};

export const Home = ({user, wallet, userLocation}) => {
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isBalanceOpen, setIsBalanceOpen] = useState(false);
  const [skuInfoModal, setSkuInfoModal] = useState({isOpen: false, sku: null});
  const [isPharmacyModalOpen, setIsPharmacyModalOpen] = useState(false);
  const [accruals, setAccruals] = useState([]);
  const [writeOffs, setWriteOffs] = useState([]);
  const [balanceLoading, setBalanceLoading] = useState(false);

  const formattedCategories = useMemo(() => {
    const mainCategories = MOCK_CATEGORIES.map((item) => {
      return {
        name: item,
        id: item
      };
    });

    return [ANY_CATEGORY, NO_CATEGORY, ...mainCategories];
  }, [MOCK_CATEGORIES]);

  console.log(search, selectedCategory);

  useEffect(() => {
    setBalanceLoading(true);
    setTimeout(() => {
      setAccruals(mockData.accruals);
      setWriteOffs(mockData.writeOffs);

      setBalanceLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      <div className="home">
        <div
          onClick={() => setIsPharmacyModalOpen(true)}
          className="home__map"
          style={{background: 'url("../images/mock-home-map.png") center center / cover no-repeat'}}
        >
          <h1 className="home__map-title">
        Ближайшие аптеки
с   кэшШШбеком ›
          </h1>

          <Badge count={8} className="home__skus-badge"/>
        </div>

        <div className="home__wrapper">
          <div className="container home__content">
            <h1 className="home__title">
          Все товары с топовым
кешШШбеком   за чеки:
            </h1>

            <Badge
              count={24}
              background="#D4E1EA"
              className="home__skus-badge"
            />

            <input
              className="search-input"
              style={{background: 'url("../images/icons/search.svg") left 16px top 12px / 24px 24px no-repeat'}}
              placeholder="Поиск по препаратам..."
              onChange={(e) => setSearch(e.target.value)}
            />

            {formattedCategories.length > 0 &&
            <Select
              onChange={(value) => setSelectedCategory(value)}
              options={formattedCategories}
              label="Категория"
              background="#F2F2F2"
            />}

            <div className="home__swiper">
              <Swiper slides={[1, 2, 3, 4, 5]}/>
            </div>

            <Alert background="#F5F5F5" className="home__skus-helper-alert">
              <span>
            Сканируйте чеки с акционными препаратами
из аптек в списке и получайте кешШШбек
              </span>
            </Alert>

            <div className="home__sku-list">
              {mockData.skus.map((sku, i) => (
                <SkuItemCard
                  key={i}
                  sku={sku}
                  onSkuInfoModal={(sku) => {
                    setSkuInfoModal({isOpen: true, sku});
                  }}
                />
              ))}
            </div>

          </div>
        </div>

        <div className="home__floating-block floating-block">
          <Button
            type="secondary"
            className="floating-block__balance-btn"
            hoverClass="hover-green"
          >
            <div
              onClick={() => setIsBalanceOpen(true)}
              className="floating-block__balance-wrapper"
            >
              <span className="floating-block__balance-title">{numeral(wallet.balance).format('0,0 $')}</span>
              <span className="floating-block__balance-subtitle">мой баланс</span>
            </div>
          </Button>

          <Button className="floating-block__scan-btn scan-btn">
            <div
              onClick={() => document.dispatchEvent(new Event('modal:scan-check'))}
              className="scan-btn__wrapper"
            >
              <span className="scan-btn__title">Сканировать чек из аптеки</span>
              <span className="scan-btn__subtitle">и получить кешШШбек</span>
            </div>
          </Button>
        </div>
      </div>

      {isPharmacyModalOpen &&
        <PharmaciesModal
          userLocation={userLocation}
          onClose={() => setIsPharmacyModalOpen(false)}
        />}

      {skuInfoModal.isOpen &&
        <SkuInfoModal
          sku={skuInfoModal.sku}
          onClose={() => setSkuInfoModal({isOpen: false, sku: null})}
        />}

      {isBalanceOpen &&
        <MyBalance
          user={user}
          wallet={wallet}
          onClose={() => setIsBalanceOpen(false)}
          accruals={accruals}
          writeOffs={writeOffs}
          balanceLoading={balanceLoading}
        />}
    </>
  );
};
