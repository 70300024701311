import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {gsap} from 'gsap';
import {toast} from 'react-toastify';
import config from '../../config';
import {intl, axios, moment, parseQR} from '../../lib';
import {
  OFD_UNKNOWN, OFD_OOFDKZ, OFD_KOFDKZ,
  RU_DATETIME, KZ_DATETIME,
} from '../../lib/fiscal';
import {
  ScanMethods,
  WelcomeScreen,
  ScanCheckFAQ,
  ManualReqEnter,
  NewDeviceQR,
  OfdOperatopHelper,
  VerificationResult,
  CheckList,
  CheckInfo
} from './screens';
import {Loading, ScanQRModal} from './components';

import './style.scss';

const SCREEN = {
  WELCOME: 'welcome',
  SCAN_METHOD: 'scan-method',
  FAQ: 'faq',
  MANUAL_REQ_ENTER: 'manual-req-enter',
  NEW_DEVICE_QR: 'new-device-qr',
  OFD_OPERATOR_HELPER: 'ofd-operator-helper',
  VERIFICATION_RESULT: 'verification-result',
  CHECK_LIST: 'check-list',
  CHECK_INFO: 'check-info'
};

export const MANUAL_REQUISITES = {
  ENTER: 'enter',
  CHECK: 'check',
  ON_PHOTO: 'on-photo'
};

let OFDS = [];

if (config.isKZ) {
  OFDS = [
    {id: OFD_OOFDKZ, name: 'АО «Казахтелеком»'},
    {id: OFD_KOFDKZ, name: 'АО «Jusan Mobile»'},
  ];
}

export const ScanCheck = ({user, onClose, scanScreen}) => {
  const modalRef = useRef(null);
  const timelineRef = useRef();
  const timeoutDuration = 700;
  const [activeScreen, setActiveScreen] = useState(scanScreen ? SCREEN.SCAN_METHOD : SCREEN.WELCOME);
  const [showScanQRModal, setShowScanQRModal] = useState(false);
  const [manualReqType, setManualReqType] = useState(MANUAL_REQUISITES.ENTER);
  const [checks, setChecks] = useState([]);
  const [activeCheck, setActiveCheck] = useState(null);
  const [isRescannedReceipt, setIsRescannedReceipt] = useState(false);
  const [skuLoading, setSkuLoading] = useState(false);
  const [isMainBtnAnimated, setIsMainBtnAnimated] = useState(false);

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  const [ofd, setOFD] = useState(OFDS.length > 0 ? OFD_OOFDKZ : OFD_UNKNOWN);
  const [fd, setFD] = useState('');
  const [fn, setFN] = useState('');
  const [fpd, setFPD] = useState('');
  const [kkt, setKKT] = useState('');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [time, setTime] = useState('12:00:00');
  const [t, setT] = useState('');
  const [sum, setSum] = useState('');

  const isWelcome = activeScreen === SCREEN.WELCOME;
  const isScanMethod = activeScreen === SCREEN.SCAN_METHOD;
  const isFAQ = activeScreen === SCREEN.FAQ;
  const isManualReqEnter = activeScreen === SCREEN.MANUAL_REQ_ENTER;
  const isNewDeviceQR = activeScreen === SCREEN.NEW_DEVICE_QR;
  const isOfdOperatorHelper = activeScreen === SCREEN.OFD_OPERATOR_HELPER;
  const isVerificationResult = activeScreen === SCREEN.VERIFICATION_RESULT;
  const isCheckList = activeScreen === SCREEN.CHECK_LIST;
  const isCheckInfo = activeScreen === SCREEN.CHECK_INFO;

  useEffect(() => {
    const disabledTimeout = setTimeout(() => {
      setIsMainBtnAnimated(true);
    }, timeoutDuration);

    return () => clearTimeout(disabledTimeout);
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      timelineRef.current = gsap.timeline()
        .fromTo('.scan-check__footer', {opacity: 0}, {opacity: 1, duration: 1})
        .fromTo('.success-btn', {x: 120}, {x: 0, duration: 0.75}, '-=1')
        .fromTo('.scan-check__body', {opacity: 0, y: 80}, {opacity: 1, y: 0, duration: 1}, '-=1');
    }, modalRef);

    return () => ctx.revert();
  }, []);

  const getScreen = () => {
    switch (activeScreen) {
    case SCREEN.WELCOME:
      return <WelcomeScreen
        user={user}
        onFaqOpen={onFaqOpen}
        checks={checks}
        onCheckListOpen={onCheckListOpen}
      />;

    case SCREEN.SCAN_METHOD:
      return (
        <ScanMethods
          onManualReqEnterOpen={onManualReqEnterOpen}
          onQRModalOpen={() => setShowScanQRModal(true)}
          onNewDeviceQROpen={onNewDeviceQROpen}
          handleQRValue={handleQRValue}
          setDate={setDate}
          setTime={setTime}
          setFPD={setFPD}
          setKKT={setKKT}
          setSum={setSum}
          setFD={setFD}
          setFN={setFN}
          setOFD={setOFD}
          setValidated={setValidated}
          setManualReqType={setManualReqType}
        />
      );

    case SCREEN.FAQ:
      return <ScanCheckFAQ onClose={onWelcomeOpen}/>;

    case SCREEN.MANUAL_REQ_ENTER:
      return <ManualReqEnter
        onClose={onScanMethodOpen}
        manualReqType={manualReqType}
        error={error}
        validated={validated}
        ofd={ofd}
        setOFD={setOFD}
        fd={fd}
        setFD={setFD}
        fn={fn}
        setFN={setFN}
        fpd={fpd}
        setFPD={setFPD}
        kkt={kkt}
        setKKT={setKKT}
        date={date}
        setDate={setDate}
        time={time}
        setTime={setTime}
        sum={sum}
        setSum={setSum}
        onOfdOperatorHelper={() => setActiveScreen(SCREEN.OFD_OPERATOR_HELPER)}
      />;

    case SCREEN.NEW_DEVICE_QR:
      return <NewDeviceQR onClose={onScanMethodOpen}/>;

    case SCREEN.OFD_OPERATOR_HELPER:
      return <OfdOperatopHelper onClose={onManualReqEnterOpen}/>;

    case SCREEN.VERIFICATION_RESULT:
      return <VerificationResult
        checks={checks}
        isRescannedReceipt={isRescannedReceipt}
        onCheckListOpen={onCheckListOpen}
      />;

    case SCREEN.CHECK_LIST:
      return <CheckList
        checks={checks}
        onClose={onWelcomeOpen}
        onCheckInfoOpen={onCheckInfoOpen}
      />;

    case SCREEN.CHECK_INFO:
      return <CheckInfo
        check={activeCheck}
        onClose={onCheckListOpen}
      />;

    default:
      return <WelcomeScreen
        user={user}
        onFaqOpen={onFaqOpen}
        checks={checks}
        onCheckListOpen={onCheckListOpen}
      />;
    }
  };

  const handleMainBtnClick = () => {
    switch (activeScreen) {
    case SCREEN.WELCOME:
      setActiveScreen(SCREEN.SCAN_METHOD);
      break;
    case SCREEN.MANUAL_REQ_ENTER:
      if (isFormValidated()) {
        send();
      }
      break;
    case SCREEN.VERIFICATION_RESULT:
      setActiveScreen(SCREEN.WELCOME);
      break;
    default:
      setActiveScreen(SCREEN.WELCOME);
      break;
    }
  };

  const handleCloseBtnClick = () => {
    if (isWelcome) {
      onClose();
      return;
    }

    onWelcomeOpen();
  };

  const getMainBtnText = () => {
    if (isManualReqEnter) return 'qrScan.modal.form.checkReceipt';
    if (isVerificationResult) return 'welcome.toFinish';

    return 'authentication.scanReceipt';
  };

  const isFormValidated = () => {
    if (!(moment(date, 'YYYY-MM-DD', true).isValid())) return false;
    if (!(moment(time, ['HH:mm:ss', 'HH:mm'], true).isValid())) return false;

    if (config.isKZ) {
      return ((fpd && +fpd > 0) && (kkt && +kkt > 0) && (sum && +sum > 0));
    }

    return ((fd && +fd > 0) && (fn && +fn > 0 && fn.length === 16) && (fpd && +fpd > 0) && (sum && +sum > 0));
  };

  const onWelcomeOpen = () => {
    setActiveScreen(SCREEN.WELCOME);
  };
  const onFaqOpen = () => {
    setActiveScreen(SCREEN.FAQ);
  };
  const onScanMethodOpen = () => {
    setActiveScreen(SCREEN.SCAN_METHOD);
  };
  const onManualReqEnterOpen = () => {
    setActiveScreen(SCREEN.MANUAL_REQ_ENTER);
  };
  const onNewDeviceQROpen = () => {
    setActiveScreen(SCREEN.NEW_DEVICE_QR);
  };
  const onCheckListOpen = () => {
    setActiveScreen(SCREEN.CHECK_LIST);
  };

  const onCheckInfoOpen = (check, status) => {
    setActiveCheck({
      check,
      status
    });
    setActiveScreen(SCREEN.CHECK_INFO);
  };

  /**
   * @param {string?} val
   */
  const handleQRValue = async (val) => {
    setError('');
    setShowScanQRModal(false);

    if (!val) {
      return;
    }

    try {
      const fiscalData = parseQR(val);

      setOFD(fiscalData.ofd);

      if (config.isKZ) {
        setFPD(fiscalData.fpd);
        setKKT(fiscalData.kkt);
        setT(fiscalData.t);
        setSum(fiscalData.sum);
      } else {
        setFD(fiscalData.fd);
        setFN(fiscalData.fn);
        setFPD(fiscalData.fpd);
        setT(fiscalData.t);
        setSum(fiscalData.sum);
      }

      const datetime = moment(fiscalData.t, config.isKZ ? KZ_DATETIME : RU_DATETIME);

      setDate(datetime.format('YYYY-MM-DD'));
      setTime(datetime.format('HH:mm:ss'));
      setValidated(true);

      setManualReqType(MANUAL_REQUISITES.CHECK);
      setActiveScreen(SCREEN.MANUAL_REQ_ENTER);
    } catch (e) {
      console.error(e);
      setError(intl.formatMessage({id: 'qrScan.modal.error.scanError'}));
    }
  };

  const send = async () => {
    try {
      setSkuLoading(true);
      setError('');

      let payload = {};

      if (config.isKZ) {
        payload = {ofd, fpd, kkt, t, sum};
      } else {
        payload = {ofd, fd, fn, fpd, t, sum};
      }

      const putRes = await axios.put('/api/v1/user/receipt', payload);

      const receipt = putRes.data?.data;

      console.log('receipt', receipt);

      getVerificationChecks();

      reset();
      setIsRescannedReceipt(false);
      setActiveScreen(SCREEN.VERIFICATION_RESULT);
    } catch (e) {
      console.error('error', e);

      if (e.response && e.response.status === 409) {
        console.log('409', e.response.data?.message);
        setIsRescannedReceipt(true);
        setActiveScreen(SCREEN.VERIFICATION_RESULT);
        return;
      }

      if (e.response && e.response.status === 400 &&
        (e.response.data?.message?.ru || '').includes('Не удалось сопоставить чек с ID кассира пользователя')) {
        setActiveScreen(SCREEN.WELCOME);
        return;
      }

      toast(intl.formatMessage({id: 'qrScan.modal.error.receiptError'}), {
        type: toast.TYPE.ERROR,
      });
      setActiveScreen(SCREEN.WELCOME);
    } finally {
      reset();
      setSkuLoading(false);
    }
  };

  const reset = () => {
    setValidated(false);
    setError('');
    setOFD(OFDS.length > 0 ? OFD_OOFDKZ : OFD_UNKNOWN);
    setFD('');
    setFN('');
    setFPD('');
    setKKT('');
    setDate(moment().format('YYYY-MM-DD'));
    setTime('12:00:00');
    setSum('');
    setManualReqType(MANUAL_REQUISITES.ENTER);
    setActiveCheck(null);
  };

  const getVerificationChecks = async () => {
    try {
      const getRes = await axios.get('/api/v1/user/receipt');

      setChecks(getRes.data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVerificationChecks();
  }, []);

  useEffect(() => {
    const datetime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss');
    const format = config.isKZ ? KZ_DATETIME : RU_DATETIME;

    setT(datetime.format(format));
  }, [date, time]);

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => document.body.classList.remove('no-scroll');
  }, []);

  return (
    <>

      <div className="dark-bg" onClick={onClose}/>

      <div ref={modalRef} className="scan-check">
        <div className="scan-check__content">
          <div className={clsx('scan-check__body', {
            ['scan-check__body--noPb']: isFAQ || isManualReqEnter || isCheckInfo,
            ['scan-check__body--alignStart']: isOfdOperatorHelper || isCheckList || isCheckInfo,
            ['scan-check__body--noPr']: isManualReqEnter || isFAQ || isScanMethod || isCheckList || isCheckInfo,
          })}>
            {getScreen()}
            {skuLoading && <div className="scan-check__loading">
              <Loading/>
            </div>}
          </div>

          <div className={clsx('scan-check__footer')}>
            <button
              onClick={handleMainBtnClick}
              className={clsx('success-btn hover-primary', {
                ['success-btn--animated']: isMainBtnAnimated,
                ['success-btn--hidden']: isScanMethod || isFAQ || isNewDeviceQR ||
                  isOfdOperatorHelper || isCheckList || isCheckInfo,
                ['success-btn--disabled']: isManualReqEnter && !isFormValidated(),
              })}
              disabled={isManualReqEnter && !isFormValidated()}
            >
              {intl.formatMessage({id: getMainBtnText()})}
            </button>

            <button
              className="footer-btn hover-white"
              onClick={handleCloseBtnClick}
            >
              <img src="/images/icons/scan-check/close.svg" alt="close"/>
            </button>
          </div>
        </div>

        {showScanQRModal &&
          <ScanQRModal
            show={showScanQRModal}
            onClose={(_, val) => {
              handleQRValue(val);
            }}
          />}
      </div>
    </>
  );
};
