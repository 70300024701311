import messages from '../messages';
import config from '../config';
import Store from './store';

const localeStore = new Store(config.localStorageLanguage);

class LocaleManager {
  /**
   * @return {string}
   */
  static _guessLocale() {
    let locale = '';

    if (typeof navigator === 'undefined') { // testing environment
      return config.locales.langs[0];
    } else { // browser renderer
      locale = navigator.language || '';
    }

    locale = locale.toLowerCase().split('-')[0];

    return locale;
  }

  /**
   * Gets locale from a store if not empty, otherwise guesses it and saves into the store
   * @return {string}
   */
  static get() {
    const localeFromStore = localeStore.get();
    let locale = localeFromStore;

    if (!locale) {
      locale = LocaleManager._guessLocale();
    }

    if (!config.locales.langs.includes(locale) || !messages.hasOwnProperty(locale)) {
      locale = config.locales.langs[0];
    }

    if (localeFromStore !== locale) {
      localeStore.set(locale);
    }

    return locale;
  }

  /**
   * @param {string} locale to set and save
   */
  static set(locale) {
    localeStore.set(locale);

    window.location.reload();
  }
}

export default LocaleManager;
