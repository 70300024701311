window.addEventListener('error', (e) => {
  const ym = window.ym;
  const info = e.colno ? ' line:' + e.lineno + ', column:' + e.colno : ' line:' + e.lineno;

  gtag('event', 'exception', {
    'description': e.message + ': ' + e.filename + info + ' -> ' + navigator.userAgent,
    'fatal': true,
  });

  if (typeof ym === 'function') {
    ym(87586071, 'reachGoal', 'ERROR', {
      message: e.message,
      stack: e.filename + info
    });
  }
});

import React, {useEffect, useState} from 'react';
import {
  HashRouter as Router,
  Route,
  Navigate
} from 'react-router-dom';
import qs from 'qs';
import {toast} from 'react-toastify';
import {RawIntlProvider} from 'react-intl';
import {intl, notificationAction, head, getCurrentPosition} from './lib';
import {sendHit} from './lib/metrika';
import config from './config';
import {Home, Login} from './screens';
import {
  PrivateRoute, ErrorBoundary, SwitchPageViews,
  Header, ScanCheck
} from './components';
import {Loading} from './ui';
import {mockData} from './mock';

toast.configure({
  autoClose: 1000,
  position: toast.POSITION.BOTTOM_RIGHT,
});

const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [userLocation, setUserLocation] = useState({lat: null, lon: null});
  const [showScanCheckModal, setScanCheckModal] = useState({isOpen: false, isScanScreen: false});

  const onScanCheckOpen = (scanScreen = true) => {
    setScanCheckModal({isOpen: true, isScanScreen: scanScreen});
    document.body.classList.add('no-scroll');
  };

  const onScanCheckClose = () => {
    setScanCheckModal({isOpen: false, isScanScreen: false});
    document.body.classList.remove('no-scroll');
  };

  const getPosition = async () => {
    try {
      const position = await getCurrentPosition();

      if (position) {
        setUserLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setUser(mockData.user);
      setWallet(mockData.wallet);

      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    getPosition();
  }, []);

  useEffect(() => {
    document.addEventListener('modal:scan-check', () => onScanCheckOpen(false));

    return () => {
      document.removeEventListener('modal:scan-check', onScanCheckOpen);
    };
  }, []);

  return <Router>
    <RawIntlProvider value={intl}>
      <ErrorBoundary>

        <Header
          user={user}
        />
        <main
          className="main"
          style={{height: '100%'}}
        >
          {loading && <Loading/>}

          {!loading &&
        <SwitchPageViews>
          <Route exact path={config.paths.home} element={<PrivateRoute user={user} />}>
            <Route
              exact path={config.paths.home}
              element={
                <Home
                  user={user}
                  wallet={wallet}
                  userLocation={userLocation}
                />
              }
            />
          </Route>

          <Route
            exact path={config.paths.login}
            element={user ? <Navigate to={config.paths.home} /> : <Login />}
          />
        </SwitchPageViews>}
        </main>

        {user && showScanCheckModal.isOpen &&
        <ScanCheck
          user={user}
          onClose={() => onScanCheckClose()}
          scanScreen={showScanCheckModal.isScanScreen}
        />}

      </ErrorBoundary>
    </RawIntlProvider>
  </Router>;
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js');

  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data) {
      const link = event.data.link;

      if (!link) return;

      const search = link.slice(link.indexOf('?'));
      const query = qs.parse(search, {ignoreQueryPrefix: true});

      if (query.open) {
        notificationAction(query.open);
        sendHit(head.notification.url, head.notification.title, head.home.url);
      }
    }
  });
}

export default App;
