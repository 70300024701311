export const notificationAction = (action) => {
  switch (action) {
  case 'bonuses':
    document.dispatchEvent(new Event('modal:bonuses'));
    break;
  case 'authentication':
    document.dispatchEvent(new Event('modal:authentication'));
    break;
  case 'welcome':
    document.dispatchEvent(new Event('modal:welcome'));
    break;
  }
};
