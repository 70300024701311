import React from 'react';
import {intl} from '../../../../lib';
import useAnimationScreen from '../../hooks/useAnimationScreen';

import './style.scss';

export const OfdOperatopHelper = ({onClose}) => {
  useAnimationScreen(true);

  return <>
    <div className="animated-screen-wrapper ofg-operatop-helper">

      <div className="back-block hover-white" onClick={onClose}>
        <img src="/images/icons/scan-check/back-arrow.svg" alt="back"/>
      </div>

      <div className="ofg-operatop-helper__wrapper">
        <div className="helper-title">
          {intl.formatMessage({id: 'scanCheck.ofdOperatorHelper.title'})}
        </div>
        <p className="helper-text">
          {intl.formatMessage({id: 'scanCheck.ofdOperatorHelper.text1'})}
        </p>
        <p className="helper-text">
          {intl.formatMessage({id: 'scanCheck.ofdOperatorHelper.text2'})}
        </p>
      </div>

    </div>
  </>;
};
