import React, {useRef, useMemo} from 'react';
import {
  YMaps, Map, Placemark, Circle
} from '@pbe/react-yandex-maps';
import {yMapApiKey} from '../../config';

import './style.scss';

export const PharmaciesMap = ({userLocation}) => {
  const mapRef = useRef();

  const mapState =useMemo(() => ({
    center: [userLocation.lat || 55.75349, userLocation.lon || 37.622093],
    zoom: 15,
  }), [userLocation]);

  const onBoundsChange = ({
    originalEvent: {oldCenter, newCenter, oldZoom, newZoom, oldBounds},
  }) => {
    const isZoomChanged = oldZoom !== newZoom;
    const boundsWidth = oldBounds[1][1] - oldBounds[0][1];
    const boundsHeight = oldBounds[1][0] - oldBounds[0][0];
    const isBoundsChanged =
      Math.abs(oldCenter[0] - newCenter[0]) / boundsHeight > 0.15 ||
      Math.abs(oldCenter[1] - newCenter[1]) / boundsWidth > 0.15;

    if (isZoomChanged || isBoundsChanged) {
      console.log('bounds changes');
    }
  };

  return (
    <YMaps query={{
      apikey: yMapApiKey
    }}>
      <Map
        instanceRef={mapRef}
        onLoad={() => {}}
        onBoundsChange={onBoundsChange}
        className="pharmaciesMap"
        state={mapState}
        modules={['control.ZoomControl']}
      >
        <Placemark
          geometry={[userLocation.lat, userLocation.lon]}
          modules={['geoObject.addon.balloon']}
          properties={{
            balloonContentBody:
              'This is balloon loaded by the Yandex.Maps API module system',
          }}
          options={{
            iconLayout: `default#image`,
            iconImageHref: '/images/icons/helper-alert-arrow.svg',
            iconImageSize: [25, 25],
            // Offset the upper left corner of the icon relative to its anchor point.
            iconImageOffset: [-10, -12]
          }}
        />
        <Circle
          geometry={[[userLocation.lat, userLocation.lon], 175]}
          options={{
            fillColor: '#F0FF93',
            fillOpacity: 0.6,
            strokeWidth: 0,
          }}
        />
      </Map>
    </YMaps>
  );
};
