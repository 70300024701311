import React from 'react';

import './style.scss';

export const Loading = () => {
  return (
    <div className="loader-container">
      <span className="loader"></span>
    </div>
  );
};
