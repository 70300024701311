import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {toast} from 'react-toastify';
import {intl, SessionQR} from '../../../../lib';
import {Alert} from '../../../../ui';
import {Loading} from '../../components';
import useAnimationScreen from '../../hooks/useAnimationScreen';

import './style.scss';

const QR_CODE_SIZE = 240;

export const NewDeviceQR = ({onClose}) => {
  /** @type {React.MutableRefObject<HTMLCanvasElement | null>} */
  const qrEl = useRef(null);
  const [loading, setLoading] = useState(true);

  useAnimationScreen(true);

  const sessionQR = useMemo(() => {
    return new SessionQR();
  }, []);

  async function render() {
    try {
      setLoading(true);

      await sessionQR.render(qrEl.current);
    } catch (e) {
      console.error(e);

      toast(intl.formatMessage({id: 'error.title'}), {
        type: toast.TYPE.ERROR,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (qrEl.current) {
      render();
    }
  }, []);

  useEffect(() => {
    return () => {
      sessionQR.destroy();
    };
  }, []);

  return <>
    <div className="animated-screen-wrapper new-device-qr">
      <div className="back-block hover-white" onClick={onClose}>
        <img src="/images/icons/scan-check/back-arrow.svg" alt="back"/>
      </div>

      <div className="main-title new-device-qr__title">
        <FormattedMessage id="scanCheck.newDeviceQR.title" values={{
          span: (chunks) => <span>{chunks}</span>,
        }} />
      </div>

      <div className="new-device-qr__qr-wrapper">
        {loading && <Loading />}
        <canvas id="login-url-qr" ref={qrEl} width={QR_CODE_SIZE} height={QR_CODE_SIZE}></canvas>
      </div>

      <Alert className="scan-check__helper-alert new-device-qr__alert" background="#F5F5F5">
        {intl.formatMessage({id: 'scanCheck.newDeviceQR.alert'})}
      </Alert>

    </div>
  </>;
};
