import Scanner from 'qr-scanner';

export default class QRScanner {
  constructor() {
    /** @type {Scanner?} */
    this.scanner = null;
  }

  destroy() {
    this.scannerStop();
  }

  /**
   * @param {HTMLVideoElement?} el
   * @return {Promise<string>} QR code value
   */
  async scanQR(el) {
    if (!el) {
      return '';
    }

    return new Promise((resolve, reject) => {
      const scanner = new Scanner(
        el,
        (res) => {
          setTimeout(() => {
            resolve(res.data);
          }, 1000);
        },
        {
          returnDetailedScanResult: true,
          maxScansPerSecond: 10,
          highlightScanRegion: true,
          highlightCodeOutline: true
        },
      );

      scanner.setCamera('environment').then(() => {
        return scanner.start();
      }).then(() => {
        this.scanner = scanner;
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   * @return {Promise<boolean>}
   */
  async scannerHasFlash() {
    return this.scanner ? await this.scanner.hasFlash() : false;
  }

  /**
   * @return {void}
   */
  scannerStop() {
    if (this.scanner) {
      this.scanner.stop();
      this.scanner = null;
    }
  }

  /**
   * @return {boolean}
   */
  isFlashOn() {
    return this.scanner ? this.scanner.isFlashOn() : false;
  }

  /**
   * @return {Promise<void>}
   */
  async toggleFlash() {
    if (this.scanner) {
      return this.scanner.toggleFlash();
    }
  }
}
