import React from 'react';
import clsx from 'clsx';

import './style.scss';

export const Alert = ({children, className, background, ...props}) => {
  return (
    <div
      style={{backgroundColor: background ? background : '#fff'}}
      className={clsx('custom-alert', className)}
      {...props}
    >
      {children}
    </div>
  );
};
