import React from 'react';
import {PharmacyCard} from '../PharmacyCard';
import {SkuItemCard} from '../SkuItemCard';
import {Alert, Popup, Button} from '../../ui';
import {mockData} from '../../mock';

import './style.scss';

export const PharmacyInfoModal = ({
  onClose, pharmacy, showScanBtn
}) => {
  return (
    <>
      <Popup
        onClose={onClose}
      >
        <Popup.Header
          title="Информация по аптеке"
          onClose={onClose}
        />

        <Popup.Body>
          <div className="pharmacyInfo">

            <Alert
              background="#F5F5F5"
              className="pharmacyInfo__helper-alert pharmacyInfo__helper-alert--top"
            >
              <span>
            Покупайте препараты из акционного списка ниже
в аптеке по этому адресу и получайте
кешШШбек на ваш бонусный счет
              </span>
            </Alert>

            <div className="pharmacyInfo__title">
            Аптека
            </div>

            <PharmacyCard pharmacy={pharmacy} noLabel={true}/>

            <Alert background="#F5F5F5" className="pharmacyInfo__helper-alert">
              <span>
            Сканируйте чеки с акционными препаратами
из аптеки и получайте кешШШбек
              </span>
            </Alert>

            <div className="pharmacyInfo__title">
            Препараты с кешШШбеком в аптеке
            </div>

            <div className="pharmacyInfo__sku-list">
              {[...new Array(4)].map((_, i) => (
                <SkuItemCard
                  key={i}
                  sku={mockData.skus[0]}
                />
              ))}
            </div>

          </div>
        </Popup.Body>
      </Popup>

      {showScanBtn && <Button className="scan-btn scan-btn--fixed">
        <div
          onClick={() => document.dispatchEvent(new Event('modal:scan-check'))}
          className="scan-btn__wrapper"
        >
          <span className="scan-btn__title">Сканировать чек из аптеки</span>
          <span className="scan-btn__subtitle">и получить кешШШбек</span>
        </div>
      </Button>}

    </>
  );
};
