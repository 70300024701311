const storageHost = 'https://storage.pharmhub.ru';
const isKZ = /\.kz$/i.test(window.location.host);
const isNative = 'isInsideNativeApp' in window && !!window.isInsideNativeApp;

module.exports = {
  isKZ,
  isNative,
  recaptchaSiteKey: '6LebqlQaAAAAAFk8m-sFrA2F48WmYHkj4JZOqm2R',
  storageHost,
  storageToken: 'd428b78aa82398be163f1b0ffb076d12',
  yMapApiKey: 'b3ef256c-ddfb-4678-9be3-628bd462fe3e',
  adminLogin: isKZ ? '+77991111111' : '+79991111111',
  // eslint-disable-next-line max-len
  policyURL: storageHost + '/uploads/21/09/20/6/8c6749b0-60f8-463c-b744-822f2f8b3bac-1136-10.09.2021_Согласие_на_обработку_Пдн_вер_1.pdf',

  gatewayHost: window.location.origin.includes('localhost') ?
    'http://localhost:9001' :
    window.location.origin,

  brand: 'ООО «ФармХаб»',
  supportEmail: 'mail@pharmhub.ru',
  supportPhone: '+7 (495) 120-01-12',
  supportPhoneKZ: '+7 (775) 007-39-46',
  tgLink: 'https://t.me/+i0RFVHzUIW9hMjI0',
  vkLink: 'https://vk.com/pharmhub_rus',
  tgKZLink: 'https://t.me/+_uxyJPEOV8o0ODFk',
  whatsAppLink: 'https://chat.whatsapp.com/JfP0cMmpxdiHK9gw3GFSU7',
  whatsAppLinkKZ: 'https://chat.whatsapp.com/IR8a8Oq1KbCEKWFhqp8vX7',

  localStorageLanguage: 'language',

  paths: {
    home: '/',
    login: '/login',
  },

  appHost: `${window.location.protocol}//${window.location.host}`,

  locales: {
    langs: ['ru', 'en', 'kz'],
    currencySign: isKZ ? '₸' : '₽',
  },

  roles: {
    MANAGER_DEMO_ROLE: 'manager_demo',
    CASHIER_DEMO_ROLE: 'cashier_demo',
    MANAGER_ROLE: 'manager',
    CASHIER_ROLE: 'cashier',
    MEDICALREP_ROLE: 'medicalrep',
    CASHIER_EXT_ROLE: 'cashierext', // isExternal = true
    MANAGER_EXT_ROLE: 'managerext', // isExternal = true
    MARKETER_ROLE: 'marketer',
    GROUP_MARKETER_ROLE: 'group_marketer',
    OWNER_ROLE: 'owner',
    OTHER_ROLE: 'other'
  },

  receiptStatuses: {
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error'
  },

  metrikaGoals: {
    ERROR: 'ERROR',
    LEARNING: 'AWARD_GOAL',
    CASHFLOW: 'CASHFLOW',
  },
};
