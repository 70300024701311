import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import config from '../../config';

export const Logo = () => {
  const location = useLocation();
  const isHomePage = location.pathname === config.paths.home;

  return (<>
    {!isHomePage && <Link to={config.paths.home}>
      <img src="/images/icons/logo.svg" alt="logo"/>
    </Link>}
    {isHomePage && <img src="/images/icons/logo.svg" alt="logo"/>}
  </>);
};
