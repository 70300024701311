import React from 'react';
import config from '../../config';
import {moment} from '../../lib';
import {sendGoal} from '../../lib/metrika';
import {Error} from '../Error';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {message: '', stack: ''},
      info: {componentStack: ''}
    };
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    this.setState({error, info});
    const time = moment().utc().format('YYYY-MM-DD HH:mm:ss');

    sendGoal({
      message: error?.message || 'Неизвестная ошибка. UTC: ' + time, stack: error?.stack || ''
    }, config.metrikaGoals.ERROR);
  }

  render() {
    const {hasError, error, info} = this.state;
    hasError && console.log(error, info);
    const {children} = this.props;

    return hasError ? <Error/> : children;
  }
}
