import React from 'react';
import {FormattedMessage} from 'react-intl';
import {isPharmacyBound} from '../../../..//lib/helper';
import {intl} from '../../../../lib';
import {Alert, HelperBtn} from '../../../../ui';
import useAnimationScreen from '../../hooks/useAnimationScreen';

export const WelcomeScreen = ({user, onFaqOpen, checks, onCheckListOpen}) => {
  useAnimationScreen();

  return <>
    <div className="animated-screen-wrapper">
      <div className="main-title">
        <img src="/images/icons/scan-check/start-icon.svg" className="scan-check__title-svg"/>
        <FormattedMessage id="scanCheck.welcomeScreen.title" values={{
          span: (chunks) => <span>{chunks}</span>,
        }} />
      </div>

      {!isPharmacyBound(user) && <Alert className="scan-check__helper-alert" background="#EBEFD2">
        {intl.formatMessage({id: 'scanCheck.alerts.pharmacyBound'})}
      </Alert>}

      <div className="scan-check-btn-container">
        {checks.length > 0 && <HelperBtn onClick={onCheckListOpen} type="primary" numberAlert={checks.length}>
          {intl.formatMessage({id: 'scanCheck.helperBtn.verificationChecks'})}
        </HelperBtn>}

        <HelperBtn type="primary" onClick={onFaqOpen}>
          {intl.formatMessage({id: 'scanCheck.helperBtn.moreAboutConditions'})}
        </HelperBtn>
      </div>
    </div>
  </>;
};
